import AboutPage from '../views/AboutPage';
import DonatePage from '../views/DonatePage';
import CalculatorFullView from '../views/CalculatorFullView';
import UpdatesPage from '../views/UpdatesPage';

export default [
    {
        path: '/',
        component: CalculatorFullView,
        isApp: true,
    },
    {
        path: '/about',
        component: AboutPage,
    },
    {
        path: '/donate',
        component: DonatePage,
    },
    {
        path: '/updates',
        component: UpdatesPage,
    },
];
