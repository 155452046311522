import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';

import VirtualizedItemList from './VirtualizedItemList';
import { DESKTOP, PHONE } from '../../../../constants/media-types';
import BoxHeader from '../../../common/BoxHeader';

const SearchListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const SearchInputWrapper = styled.div`
    padding: 6px;
`;

const ListWrapper = styled.div`
    flex: 1;
    height: calc(100% - 198px);
`;

const StyledTextField = styled(TextField)`
    margin: 0 !important;

    #search-field {
        color: #e4e7ec;
    }
`;

class SearchList extends React.PureComponent {
    render() {
        const { items, onSearch, onClickItem, screenType } = this.props;
        return (
            <SearchListWrapper>
                <BoxHeader>
                    <div className="header-title">Search</div>
                    <div className="weight-section">
                        <span className="weight">{items.length}</span> items
                    </div>
                </BoxHeader>
                <SearchInputWrapper>
                    <StyledTextField
                        id="search-field"
                        label="Search item"
                        placeholder="Search"
                        fullWidth
                        onChange={onSearch}
                        margin="normal"
                    />
                </SearchInputWrapper>
                <ListWrapper>
                    <VirtualizedItemList
                        items={items}
                        onClick={onClickItem}
                        isMobile={screenType === PHONE}
                    />
                </ListWrapper>
            </SearchListWrapper>
        );
    }
}

SearchList.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
        }),
    ),
    screenType: PropTypes.string,
};

SearchList.defaultProps = {
    items: [],
    screenType: DESKTOP,
};

export default SearchList;
