import React from 'react';
import PropTypes from 'prop-types';

export default function ItemsColumn({ items, padding }) {
    return (
        <div style={{ flexGrow: 1, flexBasis: 0 }}>
            {items.map(({ itemName, itemUrl }) => (
                <div
                    key={itemName}
                    style={
                        padding
                            ? { padding: '0 10px 0 10px', marginBottom: 10 }
                            : { marginBottom: 10 }
                    }
                >
                    <a href={itemUrl}>{itemName}</a>
                </div>
            ))}
        </div>
    );
}

ItemsColumn.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            itemName: PropTypes.string,
            itemUrl: PropTypes.string,
        }),
    ).isRequired,
    padding: PropTypes.bool,
};

ItemsColumn.defaultProps = {
    padding: false,
};
