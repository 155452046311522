import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ItemButton from './ItemButton';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 0.7em;

    @media print {
        .item-amount {
            color: black;
            font-size: 14px;
        }
    }
`;

class LockableItemButtonWithAmount extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lockHover: false,
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        return (
            nextProps.item.name !== this.props.item.name ||
            nextProps.amount !== this.props.amount ||
            nextProps.isLocked !== this.props.isLocked ||
            nextState.lockHover !== this.state.lockHover
        );
    }

    onMouseEnter = () => {
        const { item } = this.props;
        if (item.resources.length > 0) {
            this.setState({ lockHover: true });
        }
    };

    onMouseLeave = () => {
        const { lockHover } = this.state;

        if (lockHover) {
            this.setState({ lockHover: false });
        }
    };

    render() {
        const { style, item, amount, onClick, isLocked } = this.props;
        const { lockHover } = this.state;

        return (
            <Wrapper className="item-with-amount" style={style}>
                <ItemButton
                    item={item}
                    onClick={onClick}
                    isLocked={isLocked}
                    lockHover={lockHover}
                    onMouseEnter={this.onMouseEnter}
                    onMouseLeave={this.onMouseLeave}
                />
                <span className="item-amount">{amount}</span>
            </Wrapper>
        );
    }
}

LockableItemButtonWithAmount.propTypes = {
    style: PropTypes.object,
    onClick: PropTypes.func,
    item: PropTypes.shape({
        name: PropTypes.string.isRequired,
    }),
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    isLocked: PropTypes.bool,
};

LockableItemButtonWithAmount.defaultProps = {
    item: { name: '' },
    onClick: undefined,
    isLocked: false,
};

export default LockableItemButtonWithAmount;
