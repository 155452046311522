import React from 'react';
import styled from 'styled-components';

const Styled = styled.div`
    font-size: 10px;
    font-weight: 400;
    font-family: 'Elemental End';
    padding: 8px 10px;
    letter-spacing: 1px;
    background-color: #55967e;
    color: #e4e7ec;

    .weight-section {
        font-size: 10px;
        font-family: monospace;
    }
`;

const BoxHeader = ({ children }) => {
    return <Styled className="box-header">{children}</Styled>;
};

export default BoxHeader;
