import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const StyledFooter = styled.footer`
    font-size: 12px;
    padding: 16px;
    background-color: #263959;
    text-align: center;
    color: #e4e7ec;

    .rights-reserved {
        margin-bottom: 8px;
    }

    .support {
        margin-bottom: 8px;
    }

    @media print {
        display: none !important;
    }
`;

export default function Footer() {
    return (
        <StyledFooter>
            <div className="rights-reserved">
                Game content and materials are trademarks and copyrights of
                their respective publisher and its licensors. All rights
                reserved.
            </div>
            <div className="support">
                <Link to="/donate">Buy me a cup of coffee</Link> to support my
                work.
            </div>
            <div className="copyright">© 2020 Akia Vongdara</div>
        </StyledFooter>
    );
}
