import React from 'react';
import styled from 'styled-components';
import version from '../../../version.json';
import Update01 from './Update01';
import Update02 from './Update02';
import Update03 from './Update03';
import { Update04 } from './Update04';
import { Update05 } from './Update05';
import { Update06 } from './Update06';
import { Update07 } from './Update07';
import { Update08 } from './Update08';
import { Update09 } from './Update09';

const StyledUpdates = styled.div`
    padding: 12px;
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
    box-sizing: border-box;
    letter-spacing: 1px;
    line-height: 24px;
    color: #e4e7ec;
    padding-top: 24px;

    p {
        margin: 0 0 36px;
    }

    h3 {
        margin-top: 36px;
        color: #263959;
    }

    ul {
        margin-bottom: 36px;
    }

    a {
        color: #55967e;
        font-weight: bold;
        text-decoration: unset;
        transition: 200ms color;
        cursor: pointer;

        &:hover {
            color: #e4e7ec;
        }
    }

    .card {
        margin-bottom: 24px;
    }
`;

export default function UpdatesPage() {
    localStorage.setItem('ark-calc-version', version.version);
    return (
        <StyledUpdates>
            <Update09 />
            <Update08 />
            <Update07 />
            <Update06 />
            <Update05 />
            <Update04 />
            <Update03 />
            <Update02 />
            <Update01 />
        </StyledUpdates>
    );
}
