import React from 'react';
import PropTypes from 'prop-types';
import ItemsColumn from './ItemsColumn';

export default function AddedItemsSection({ addedItems, numColumns }) {
    const numAdded = addedItems.length;
    const columnItems = [];

    for (let i = 0; i < numColumns; i += 1) {
        const start = (numAdded * i) / numColumns;
        const end = (numAdded * (i + 1)) / numColumns;
        const items = addedItems.slice(
            start,
            end > addedItems.length ? addedItems.length : end,
        );

        columnItems.push(items);
    }

    if (numAdded === 0) {
        return null;
    }

    return (
        <>
            <h4>Added {numAdded} items</h4>
            <hr />
            <div style={{ display: 'flex', fontSize: 14 }}>
                {columnItems.map((items, index) => (
                    <ItemsColumn
                        key={`added-column-${index}`}
                        items={items}
                        padding={
                            index !== 0 && index !== columnItems.length - 1
                        }
                    />
                ))}
            </div>
        </>
    );
}

AddedItemsSection.propTypes = {
    addedItems: PropTypes.arrayOf(
        PropTypes.shape({
            itemName: PropTypes.string,
            itemUrl: PropTypes.string,
        }),
    ).isRequired,
    numColumns: PropTypes.number,
};

AddedItemsSection.defaultProps = {
    numColumns: 3,
};
