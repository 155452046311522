import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import nanomemoize from 'nano-memoize';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import { pure } from 'recompose';

import ItemButton from '../../../common/ItemButton';
import { decimalIfIrrational } from '../../../../utils/number-manipulation';
import { prevent } from '../../../wrappers/Prevent';
import LockableItemButtonWithAmount from '../../../common/LockableItemButtonWithAmount';
import { removeDLCFromName } from '../../../../api/ARKScraper/scraper-helper';

const Container = styled.li`
    @media print {
        margin-top: 16px;
    }
`;

const LineItemWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 10px;
    width: fit-content;

    button {
        color: #e4e7ec;
    }

    .amount-update-btn {
        background-color: #55967e;
        transition: 200s background-color;

        &:hover {
            background-color: #437563 !important;
        }

        @media print {
            display: none;
        }
    }
`;

const StyledTextField = styled(TextField)`
    width: 40px;

    input {
        text-align: center;
        font-size: 12px;
        padding: 2px 0;
        font-family: monospace;

        @media print {
            color: black;
            font-size: 24px;
            font-weight: 600;
        }
    }

    @media print {
        .MuiInput-underline:before, .MuiInput-underline:after {
            border: 0;
        }
    }
`;

const PrinterFriendlyItemName = styled.div`
    display: none;

    @media print {
        color: black;
        margin-left: 10px;
        display: initial;
    }
`;

const styles = {
    small: {
        width: 30,
        height: 30,
        padding: 4,
        margin: '0 12px 0',
    },
};

const StyledIconButton = styled(IconButton)`
    svg {
        font-size: 16px;
    }
`;

const RemoveItemButton = styled(ItemButton)`
    :hover {
        background-color: #ef5350 !important;
    }
`;

const onKeyDown = nanomemoize((onAddAmount, onRemoveAmount) => (e) => {
    if (e.key === 'ArrowUp' && onAddAmount) {
        onAddAmount();
    } else if (e.key === 'ArrowDown' && onRemoveAmount) {
        onRemoveAmount();
    }
});

const PureIconButton = pure(StyledIconButton);
const PureRemoveItemButton = prevent(
    RemoveItemButton,
    ({ prevProps, nextProps }) => {
        const shouldUpdate =
            prevProps.onClickRemove !== nextProps.onClickRemove ||
            prevProps.item.itemUrl !== nextProps.item.itemUrl;
        return shouldUpdate;
    },
);

class LineItem extends React.Component {
    shouldComponentUpdate(nextProps) {
        return (
            nextProps.amount !== this.props.amount ||
            nextProps.lockedResources !== this.props.lockedResources
        );
    }

    render() {
        const {
            item,
            amount: numItems,
            lockedResources,
            itemTable,
            onClickRemove,
            onChangeAmount,
            onAddAmount,
            onRemoveAmount,
            onClickResource,
        } = this.props;
        // TODO: Yields should come in clean. Probably should clean items.json
        const yields = item.yields || 1;
        return (
            <Container>
                <PrinterFriendlyItemName>{removeDLCFromName(item.name)}</PrinterFriendlyItemName>
                <LineItemWrapper>
                    <PureRemoveItemButton
                        item={item}
                        onClick={onClickRemove(item)}
                    />
                    <PureIconButton
                        className="amount-update-btn"
                        onClick={onRemoveAmount(item, yields)}
                        iconclassname="material-icons"
                        style={styles.small}
                    >
                        <RemoveIcon />
                    </PureIconButton>
                    <StyledTextField
                        id="number"
                        placeholder="Amount"
                        value={numItems}
                        onChange={onChangeAmount(item)}
                        onKeyDown={onKeyDown(
                            onAddAmount(item, 1),
                            onRemoveAmount(item, 1),
                        )}
                        type="text"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <PureIconButton
                        className="amount-update-btn"
                        onClick={onAddAmount(item, yields)}
                        iconclassname="material-icons"
                        style={styles.small}
                    >
                        <AddIcon />
                    </PureIconButton>
                    {item.resources.map((resource) => {
                        const amount = (resource.amount * numItems) / yields;
                        return (
                            <LockableItemButtonWithAmount
                                key={`r-${resource.itemName}`}
                                item={itemTable[resource.itemName]}
                                amount={decimalIfIrrational(
                                    amount,
                                    2,
                                ).toLocaleString()}
                                onClick={onClickResource(item, resource)}
                                isLocked={lockedResources[resource.itemName]}
                            />
                        );
                    })}
                </LineItemWrapper>
            </Container>
        );
    }
}

LineItem.propTypes = {
    item: PropTypes.shape({
        name: PropTypes.string.isRequired,
    }).isRequired,
    amount: PropTypes.number.isRequired,
    onClickRemove: PropTypes.func,
    itemTable: PropTypes.object.isRequired,
    onChangeAmount: PropTypes.func,
    onAddAmount: PropTypes.func,
    onRemoveAmount: PropTypes.func,
};

LineItem.defaultProps = {
    onClickRemove: () => {},
    onChangeAmount: () => {},
    onAddAmount: () => {},
    onRemoveAmount: () => {},
};

export default LineItem;
