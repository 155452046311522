import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import List from 'react-virtualized/dist/commonjs/List';
import Grid from 'react-virtualized/dist/commonjs/Grid';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import ItemRowButton from './ItemRowButton';
import ItemButton from '../../../common/ItemButton';
import { media } from '../../../../utils/media';

export const GRID_ITEM_SIZE = 56;
const ITEM_HEIGHT = 42;

const ItemListWrapper = styled.div`
    height: 100%;

    button {
        color: #e4e7ec;
        transition: 200ms color;

        &:hover {
            color: #6d819c;
        }
    }

    .ReactVirtualized__Grid__innerScrollContainer {
        overflow: auto !important;
    }

    .ReactVirtualized__Grid__innerScrollContainer button {
        padding: 0 6px;
        justify-content: left !important;
        width: fit-content !important;
        min-width: 100% !important;
    }

    ${media.phone`
      .ReactVirtualized__Grid__innerScrollContainer {
        max-height: none !important;
        height: 100% !important;
      }

      .ReactVirtualized__Grid__innerScrollContainer button {
        justify-content: center !important;
        img {
          height: ${GRID_ITEM_SIZE - 24}px;
          width: ${GRID_ITEM_SIZE - 24}px;
        }
      }
    `};
`;

const CellWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: ${GRID_ITEM_SIZE}px !important;

    button {
        width: 100% !important;
        height: 100% !important;
    }
`;

const iconStyle = {
    padding: '0',
    height: `${GRID_ITEM_SIZE}px`,
    width: `${GRID_ITEM_SIZE}px`,
};

class VirtualizedItemList extends Component {
    constructor(props) {
        super(props);
        this.itemRenderer = props.isMobile
            ? this.gridRenderer
            : this.listRenderer;
        this.iconPosition = {};
        this.iconPosition[props.items.length - 1] = 'bottom-start';
        this.iconPosition[0] = 'bottom-end';
    }

    componentWillReceiveProps(props) {
        this.itemRenderer = props.isMobile
            ? this.gridRenderer
            : this.listRenderer;
        this.iconPosition = {};
        this.iconPosition[props.items.length - 1] = 'bottom-start';
        this.iconPosition[0] = 'bottom-end';
    }

    rowRenderer = ({ key, index, style }) => (
        <ItemRowButton
            key={key}
            item={this.props.items[index]}
            style={{ ...style, textAlign: 'left' }}
            onClick={this.props.onClick(this.props.items[index])}
        />
    );

    /* eslint jsx-a11y/alt-text: off */
    cellRenderer = ({ columnIndex, key, style }) => (
        <CellWrapper key={key} style={style}>
            <ItemButton
                item={this.props.items[columnIndex]}
                tooltipPosition={this.iconPosition[columnIndex]}
                style={iconStyle}
                onClick={this.props.onClick(this.props.items[columnIndex])}
            />
        </CellWrapper>
    );

    listRenderer = ({ height, width }) => (
        <List
            width={width}
            height={height}
            rowCount={this.props.items.length}
            rowHeight={ITEM_HEIGHT}
            rowRenderer={this.rowRenderer}
        />
    );

    gridRenderer = ({ height, width }) => (
        <Grid
            cellRenderer={this.cellRenderer}
            columnCount={this.props.items.length}
            columnWidth={GRID_ITEM_SIZE}
            height={height}
            rowCount={1}
            rowHeight={GRID_ITEM_SIZE + 36}
            width={width}
        />
    );

    render() {
        return (
            <ItemListWrapper>
                <AutoSizer>
                    {({ height, width }) =>
                        this.itemRenderer({ height, width })
                    }
                </AutoSizer>
            </ItemListWrapper>
        );
    }
}

VirtualizedItemList.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            itemUrl: PropTypes.string,
        }),
    ).isRequired,
    onClick: PropTypes.func,
    isMobile: PropTypes.bool,
};

VirtualizedItemList.defaultProps = {
    onClick: () => {},
};

export default VirtualizedItemList;
