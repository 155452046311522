import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import LockIcon from '@material-ui/icons/Lock';
import Tooltip from '@material-ui/core/Tooltip';
import { getItemImageUrl } from '../../utils/item-parsing';
import { NO_IMAGE } from '../../constants/default-images';

const ItemImage = styled.img`
    width: 30px;
    height: 30px;
`;

const iconStyle = {
    padding: '0',
    height: '40px',
    width: '40px',
    position: 'relative',
    color: '#3e3e3e',
};

const lockedIconStyle = {
    position: 'absolute',
    color: '#e4e7ec',
};

const lockedImageStyle = {
    opacity: 0.4,
};

class ItemButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: false,
        };
        this.imgRef = React.createRef();
    }

    shouldComponentUpdate(nextProps, nextState) {
        const shouldUpdate =
            nextProps.item.name !== this.props.item.name ||
            nextProps.className !== this.props.className ||
            nextState.error !== this.state.error ||
            nextProps.isLocked !== this.props.isLocked ||
            nextProps.lockHover !== this.props.lockHover;
        return shouldUpdate;
    }

    // componentWillUpdate() {
    //     if (
    //         this.imgRef.current.src.indexOf(
    //             getItemImageUrl(this.props.item.name),
    //         ) < 0
    //     ) {
    //         this.state.error = false;
    //     }
    // }

    handleError = () => {
        this.setState({ error: true });
    };

    handleSuccess = () => {
        if (
            this.state.error &&
            this.imgRef.current.src.indexOf(NO_IMAGE) >= 0
        ) {
            return;
        }
        this.setState({ error: false });
    };

    render() {
        const { error } = this.state;
        const {
            item,
            tooltipPosition,
            className,
            onClick,
            isLocked,
            lockHover,
            onMouseEnter,
            onMouseLeave,
        } = this.props;
        const src = error ? NO_IMAGE : getItemImageUrl(item.name);

        return (
            <Tooltip title={item.name} placement={tooltipPosition}>
                <IconButton
                    className={`${className}`}
                    style={iconStyle}
                    onClick={onClick}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                >
                    <ItemImage
                        src={src}
                        className={src === NO_IMAGE ? 'no-image' : ''}
                        alt={item.name}
                        style={
                            isLocked || lockHover ? lockedImageStyle : undefined
                        }
                        onError={this.handleError}
                        onLoad={this.handleSuccess}
                        ref={this.imgRef}
                    />
                    {(isLocked || lockHover) && (
                        <LockIcon style={lockedIconStyle} />
                    )}
                </IconButton>
            </Tooltip>
        );
    }
}

ItemButton.propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
    style: PropTypes.object,
    item: PropTypes.shape({
        name: PropTypes.string.isRequired,
        itemUrl: PropTypes.string,
    }).isRequired,
    tooltipPosition: PropTypes.string,
};

ItemButton.defaultProps = {
    item: {
        itemUrl: '',
        name: '',
    },
    style: iconStyle,
    tooltipPosition: 'bottom',
};

export default ItemButton;
