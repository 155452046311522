import { getResourceAmount } from './get-resource-amount';

export const isResourceUpdateARename = (
    resource1,
    resource2,
    resource1Name,
    resource2Name,
) => {
    if (resource1 && resource2) {
        const resource1Amount = getResourceAmount(resource1);
        const resource2Amount = getResourceAmount(resource2);
        const isRenameType =
            (resource1.type === 'added' && resource2.type === 'deleted') ||
            (resource1.type === 'deleted' && resource2.type === 'added');

        if (isRenameType && resource1Amount === resource2Amount) {
            return true;
        }

        if (
            isRenameType &&
            resource1Name &&
            resource2Name &&
            (resource1Name.includes(resource2Name) ||
                resource2Name.includes(resource1Name))
        ) {
            return true;
        }
    }

    return false;
};
