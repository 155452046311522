import { connect } from 'react-redux';
import FilterDrawer from './FilterPersistentDrawer';
import {
    selectFilter,
    toggleFilter,
} from '../../../../store/actions/calculator';

const mapStateToProps = ({ calculator: { filterItems, isFilterBarOpen } }) => ({
    items: filterItems,
    open: isFilterBarOpen,
    // open: true,
});

const mapDispatchToProps = (dispatch) => {
    return {
        onSelectItem: (index) => () => {
            dispatch(selectFilter(index));
        },
        onClickClose: () => {
            dispatch(toggleFilter());
        },
    };
};

const FilterDrawerComponent = connect(
    mapStateToProps,
    mapDispatchToProps,
)(FilterDrawer);

export default FilterDrawerComponent;
