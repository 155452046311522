import React from 'react';
import PropTypes from 'prop-types';

export default function InfoSection({
    numDeletedItems,
    numAddedItems,
    numUpdatedItems,
}) {
    return (
        <div style={{ marginBottom: 32 }}>
            <h3 style={{ color: 'white', marginTop: 0 }}>Summary</h3>

            <article>
                <p>
                    Huge items updates! Sorry it has taken a while. There was a
                    layout change on the wiki, and my web scraper could not
                    adjust to the changes well. I had to scrap everything and
                    create a new scraper because that codebase was just
                    horrendous and unmaintainable. But this time I recreated it
                    in TypeScript and I feel much better :)
                </p>
                <p>
                    The scraper now also looks for Mod items such as Super
                    Structures and Structures Plus.
                </p>
                <p>Enjoy!</p>
            </article>

            <article>
                <ul>
                    <li>Fix Dye filtering issue</li>
                    {numAddedItems > 0 && <li>Added {numAddedItems} items</li>}
                    {numUpdatedItems > 0 && (
                        <li>Updated {numUpdatedItems} items</li>
                    )}
                    {numDeletedItems > 0 && (
                        <li>
                            Removed {numDeletedItems} unused items from database
                            (mostly uncraftables, unused resources, or the item
                            may have been renamed)
                        </li>
                    )}
                </ul>
            </article>
        </div>
    );
}

InfoSection.propTypes = {
    numDeletedItems: PropTypes.number,
    numAddedItems: PropTypes.number,
    numUpdatedItems: PropTypes.number,
};

InfoSection.defaultProps = {
    numDeletedItems: 0,
    numAddedItems: 0,
    numUpdatedItems: 0,
};
