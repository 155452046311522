import { css } from 'styled-components';

export const sizes = {
    desktopLg: 1480,
    desktopMd: 1280,
    desktopSm: 992,
    desktop: 992,
    tablet: 768,
    tabletLg: 768,
    tabletMd: 640,
    tabletSm: 576,
    phone: 480,
    mobile: 480,
    mobileLg: 480,
    mobileMd: 411,
    mobileSm: 360,
};

// Iterate through the sizes and create a media template
const mediaFromSizes = Object.keys(sizes).reduce((acc, label) => {
    acc[label] = (...args) => css`
        @media (max-width: ${sizes[label] / 16}em) {
            ${css(...args)};
        }
    `;
    return acc;
}, {});

mediaFromSizes.aboveDesktop = (...args) => css`
    @media (min-width: ${(sizes.desktop + 1) / 16}em) {
        ${css(...args)};
    }
`;

mediaFromSizes.aboveTablet = (...args) => css`
    @media (min-width: ${(sizes.tablet + 1) / 16}em) {
        ${css(...args)};
    }
`;

mediaFromSizes.aboveMobile = (...args) => css`
    @media (min-width: ${(sizes.mobile + 1) / 16}em) {
        ${css(...args)};
    }
`;

export const media = mediaFromSizes;
