import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { media } from '../../../utils/media';
import version from '../../../version';

const StyledNav = styled.nav`
    justify-self: end;
    flex: 1;
    display: flex;
    justify-content: flex-end;
    font-size: 12px;

    a {
        color: #e4e7ec;
        margin-left: 20px;
        text-decoration: unset;
        font-family: 'Elemental End';

        transition: 200ms color;

        &:hover,
        &.active {
            color: #55967e;
        }

        &:first-child {
            margin-left: 0;
        }
    }

    .update-nav {
        position: relative;

        .new {
            position: absolute;
            top: -100%;
            left: 0;
            font-size: 10px;
            color: #55967e;
        }
    }

    ${media.phone`
        display: none;
    `};
`;

export default function Nav() {
    const hasNewUpdate =
        localStorage.getItem('ark-calc-version') !== version.version;

    return (
        <StyledNav>
            <NavLink to="/about" activeClassName="active">
                About
            </NavLink>
            <NavLink
                to="/updates"
                activeClassName="active"
                className="update-nav"
            >
                Updates
                {hasNewUpdate && <span className="new">New</span>}
            </NavLink>
        </StyledNav>
    );
}
