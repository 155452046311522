import React from 'react';
import styled from 'styled-components';

const StyledCard = styled.div`
    background-color: #263959;

    .card-header {
        background-color: #55967e !important;
        padding: 12px 24px;
        margin: 0;
    }

    .card-title {
        margin: 0;
    }

    .card-subtitle {
        font-size: 12px;
        font-weight: 400;
    }

    .body {
        padding: 24px;
    }

    p:last-child {
        margin-bottom: 0;
    }
`;

export default function Card({ children, title, subtitle }) {
    return (
        <StyledCard className="card">
            <div className="card-header">
                <h3 className="card-title">{title}</h3>
                <div className="card-subtitle">{subtitle}</div>
            </div>

            <div className="body">{children}</div>
        </StyledCard>
    );
}
