import React from 'react';

class Prevent extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    const prevProps = this.props;
    const prevState = this.state;
    return this.props.shouldComponentUpdate({
      prevProps,
      nextProps,
      prevState,
      nextState,
    });
  }

  render() {
    const { renderComponent, ...rest } = this.props;
    return renderComponent(rest);
  }
}

Prevent.defaultProps = {
  shouldComponentUpdate: () => {},
};

export const prevent = (WrappedComponent, shouldComponentUpdate) => {
  const renderComponent = (props) => <WrappedComponent {...props} />;
  const ConnectedComponent = (props) => (
    <Prevent
      renderComponent={renderComponent}
      shouldComponentUpdate={shouldComponentUpdate}
      {...props}
    />
  );
  return ConnectedComponent;
};

export default Prevent;
