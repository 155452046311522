import { connect } from 'react-redux';
import ResourceList from './ResourceListComponent';
import {
    selectItem,
    toggleGettingBaseResource,
} from '../../../../store/actions/calculator';

const mapStateToProps = ({
    calculator: {
        resources,
        itemTable,
        isGettingBaseResources,
        totalResourceWeight,
        weightUnknown,
    },
}) => ({
    resources,
    isGettingBaseResources,
    itemTable,
    totalResourceWeight,
    weightUnknown,
});

const mapDispatchToProps = (dispatch) => {
    return {
        onClickItem: (item) => () => {
            dispatch(selectItem(item));
        },
        onToggleResourceType: () => {
            dispatch(toggleGettingBaseResource());
        },
        onChangeResourceType: () => {
            dispatch(toggleGettingBaseResource());
        },
    };
};

const ResourceListComponent = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ResourceList);

export default ResourceListComponent;
