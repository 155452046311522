import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/styles';

import ItemButtonWithAmount from '../../../common/ItemButtonWithAmount';
import { decimalIfIrrational } from '../../../../utils/number-manipulation';
import { media } from '../../../../utils/media';
import BoxHeader from '../../../common/BoxHeader';

const ResourceListWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 100%;

    ${media.phone`
		flex-direction: column;
	`}

    .resource-section {
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow: auto;

        .resources-wrapper {
            flex: 1;
            box-sizing: border-box;
            min-height: 73px;
            display: flex;
            height: 100%;
            align-items: center;

            .item-with-amount {
                margin-right: 8px;
                font-family: monospace;
                font-size: 11px;
            }

            .item-with-amount:last-child() {
                margin-right: 0;
            }
        }

        .box-header {
            display: flex;

            .resource-title-section {
                flex: 1;
                font-family: 'Elemental End';
            }

            .resource-switch-section {
                .MuiFormControlLabel-label {
                    font-size: 12px;
                }

                @media print {
                    display: none;
                }
            }
        }
    }
`;

const Resources = styled.div`
    display: flex;
    flex-direction: row;
    overflow: auto;
    flex: 1;
    height: 100%;
    padding: 10px;
    box-sizing: border-box;

    ${media.phone`
        flex-wrap: unset;
        justify-content: unset;
    `};
`;

const BASE = 'base';
const RESOURCE = 'resource';

const BlueSwitch = withStyles({
    switchBase: {
        //   color: purple[300],
        '&$checked': {
            color: '#263959',
        },
        '&$checked + $track': {
            backgroundColor: '#263959',
        },
    },
    checked: {},
    track: {},
})(Switch);

class ResourceList extends React.PureComponent {
    render() {
        const {
            resources,
            isGettingBaseResources,
            onChangeResourceType,
            totalResourceWeight,
            weightUnknown,
        } = this.props;
        let resourceWeight = decimalIfIrrational(
            totalResourceWeight,
            2,
        ).toLocaleString(undefined, {
            minimumFractionDigits: 2,
        });

        if (weightUnknown) {
            resourceWeight = `~ ${resourceWeight}`;
        }

        const SwitchComponent = (
            <BlueSwitch
                checked={isGettingBaseResources}
                onChange={() =>
                    onChangeResourceType(
                        isGettingBaseResources ? RESOURCE : BASE,
                    )
                }
                value="checkedA"
                color="primary"
            />
        );

        return (
            <ResourceListWrapper>
                <div className="resource-section">
                    <BoxHeader>
                        <div className="resource-title-section">
                            <div className="header-title">
                                Total {isGettingBaseResources ? 'Base ' : ' '}
                                Resources
                            </div>
                            <div className="weight-section">
                                Weight:{' '}
                                <span className="weight">{resourceWeight}</span>
                            </div>
                        </div>
                        <div className="resource-switch-section">
                            <FormControlLabel
                                control={SwitchComponent}
                                label="Show base resource"
                            />
                        </div>
                    </BoxHeader>
                    <div className="resources-wrapper">
                        <Resources>
                            {resources.map((resource) => {
                                return (
                                    <ItemButtonWithAmount
                                        key={`btn-amount-${resource.item.name}`}
                                        item={resource.item}
                                        amount={decimalIfIrrational(
                                            resource.amount,
                                            2,
                                        )}
                                    />
                                );
                            })}
                            {resources.length === 0 && (
                                <div className="default-text">Empty</div>
                            )}
                        </Resources>
                    </div>
                </div>
            </ResourceListWrapper>
        );
    }
}

ResourceList.defaultProps = {
    isGettingBaseResources: false,
    onChangeResourceType: undefined,
    totalItemWeight: 0,
    totalResourceWeight: 0,
    weightUnknown: false,
    resources: [],
};

ResourceList.propTypes = {
    isGettingBaseResources: PropTypes.bool,
    onChangeResourceType: PropTypes.func,
    totalItemWeight: PropTypes.number,
    totalResourceWeight: PropTypes.number,
    weightUnknown: PropTypes.bool,
    resources: PropTypes.arrayOf(
        PropTypes.shape({
            item: PropTypes.shape({
                name: PropTypes.string,
                weight: PropTypes.number,
            }),
            amount: PropTypes.number,
        }),
    ),
};
export default ResourceList;
