import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import { getItemImageUrl } from '../../../../utils/item-parsing';
import { NO_IMAGE } from '../../../../constants/default-images';
import { media } from '../../../../utils/media';
import { getDLCImageSrc } from '../../../../api/ARKScraper/get-dlc-image-src';
// import './Button.css';

const ItemImage = styled.img`
    width: 30px;
    height: 30px;
`;

const ButtonLabel = styled.span`
    font-weight: 100;
    font-size: 12px;
    white-space: nowrap;
    padding-left: 8px;
    display: flex;
    align-items: center;

    img {
        margin-left: 8px;
    }

    ${media.tablet`
        font-size: 10px;
    `};

    ${media.desktop`
        font-size: 12px;
    `};
`;

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: left;
    text-transform: none;
`;

class ItemRowButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: false,
        };
        this.imgRef = React.createRef();
    }

    shouldComponentUpdate(nextProps, nextState) {
        const shouldUpdate =
            nextProps.item.name !== this.props.item.name ||
            nextProps.className !== this.props.className ||
            nextState.error !== this.state.error;
        return shouldUpdate;
    }

    // componentWillUpdate() {
    //     if (
    //         this.imgRef.current &&
    //         this.imgRef.current.src.indexOf(
    //             getItemImageUrl(this.props.item.name),
    //         ) < 0
    //     ) {
    //         this.state.error = false;
    //     }
    // }

    handleError = () => {
        this.setState({ error: true });
    };

    handleSuccess = () => {
        if (
            this.state.error &&
            this.imgRef.current &&
            this.imgRef.current.src.indexOf(NO_IMAGE) >= 0
        ) {
            return;
        }
        this.setState({ error: false });
    };

    render() {
        const { item, style, onClick } = this.props;
        const { error } = this.state;
        const src = error ? NO_IMAGE : getItemImageUrl(item.name);
        return (
            <Button style={{ ...style }} onClick={onClick}>
                <Wrapper>
                    <ItemImage
                        src={src}
                        className={src === NO_IMAGE ? 'no-image' : ''}
                        onError={this.handleError}
                        onLoad={this.handleSuccess}
                        ref={this.imgRef}
                        title={item.name}
                    />
                    <ButtonLabel>
                        {item.displayName}
                        {item.dlc && (
                            <img
                                width={16}
                                height={16}
                                src={getDLCImageSrc(item.dlc)}
                                alt={item.dlc}
                                title={item.dlc}
                            />
                        )}
                    </ButtonLabel>
                </Wrapper>
            </Button>
        );
    }
}

ItemRowButton.propTypes = {
    item: PropTypes.PropTypes.shape({
        name: PropTypes.string.isRequired,
        itemUrl: PropTypes.string.isRequired,
    }),
    onClick: PropTypes.func,
    style: PropTypes.object.isRequired,
};

ItemRowButton.defaultProps = {
    onClick: () => () => ({}),
    item: {
        name: 'Fake Item',
        itemUrl: '',
    },
};

export default ItemRowButton;
