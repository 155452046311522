import desktopRoutes from '../components/routes/desktop-routes';
import titleize from './titleize';

export default function getPageTitle(pathname) {
    const defaultTitle = 'ARK Resource Calculator';

    if (pathname === '/') {
        return defaultTitle;
    }

    // TODO: Get all routes instead of just desktops
    const pathRoute = desktopRoutes.find((route) => {
        return route.path.includes(pathname);
    });

    if (pathRoute) {
        const titleizedPath = titleize(pathname.substring(1));
        return `${titleizedPath} - ${defaultTitle}`;
    }

    return `Unknown - ${defaultTitle}`;
}
