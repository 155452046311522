import React from 'react';
import { Link } from 'react-router-dom';
import Card from '../../common/Card';

const Update01 = () => {
    return (
        <Card title="Update v2.0.0" subtitle="August 7th, 2019">
            <p>
                Hi everybody! Version 2.0.0 has been released! Sorry for taking
                so long!! There&apos;s a lot of great improvements in this
                version. Also, let me know if you have any suggestions! I&apos;m
                always open for improvements.
            </p>
            <ul>
                <li>Faster search</li>
                <li>Faster item loading</li>
                <li>Chemistry bench items added</li>
                <li>Items have icons indicating what DLC they&apos;re from</li>
                <li>
                    When selecting an item, it’ll add the items by the number of
                    yields rather than just 1.
                </li>
                <li>Weight is shown for both items and resources.</li>
                <li>
                    Users can toggle between displaying regular resources and
                    base resources.
                </li>
                <li>
                    Users can lock resources to prevent calculating their base
                    resources.
                </li>
            </ul>

            <p>
                If you&apos;d like to give me any feedback or suggestions,
                please{' '}
                <a
                    href="mailto:vongdarakia@gmail.com?subject=ARK Resource Calculator [Feedback]"
                    target="_top"
                >
                    email me
                </a>{' '}
                about it. Also, if you like my app and would like to support my
                work, you can do that <Link to="donate">here</Link> :)
            </p>
        </Card>
    );
};

export default Update01;
