import { connect } from 'react-redux';
import HeaderBarComponent from './HeaderBarComponent';
import { toggleFilter as toggleFilterAction } from '../../../store/actions/calculator';

const mapStateToProps = (state) => ({
    isFilterBarOpen: state.calculator.isFilterBarOpen,
});

const mapDispatchToProps = {
    onDrawerOpen: toggleFilterAction,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(HeaderBarComponent);
