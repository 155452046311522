import React from 'react';
import { Switch, Route } from 'react-router-dom';
import ErrorPage from '../views/Error';
import desktopRoutes from './desktop-routes';
import DesktopHeaderLayout from '../common/layouts/DesktopHeaderLayout';

const ARKRoutes = () => (
    <Switch>
        {desktopRoutes.map(({ component, path, isApp }) => (
            <Route
                exact
                path={path}
                key={path}
                render={(route) => (
                    <DesktopHeaderLayout
                        component={component}
                        route={route}
                        isApp={isApp}
                    />
                )}
            />
        ))}
        <Route path="/*" component={ErrorPage} />
    </Switch>
);

export default ARKRoutes;
