import { connect } from 'react-redux';
import SelectedItemsList from './SelectedItemsListComponent';
import {
    deselectItem,
    addItem,
    removeItem,
    changeItemAmount,
    lockResource,
    clearSelectedItems,
} from '../../../../store/actions/calculator';
import { itemTable } from '../data';

const mapStateToProps = ({
    calculator: {
        selectedItems,
        items,
        itemTable,
        resources,
        isGettingBaseResources,
        totalItemWeight,
        weightUnknown,
    },
}) => ({
    selectedItems,
    items,
    itemTable,
    resources,
    isGettingBaseResources,
    totalItemWeight,
    weightUnknown,
});

const mapDispatchToProps = (dispatch) => {
    return {
        onClickRemove: (item) => () => {
            dispatch(deselectItem(item));
        },
        onAddAmount: (item, amount) => () => {
            dispatch(addItem(item, amount));
        },
        onRemoveAmount: (item, amount) => () => {
            dispatch(removeItem(item, amount));
        },
        onChangeAmount: (item) => (e) => {
            let amount = 0;
            if (!Number.isNaN(e.target.value)) {
                amount = parseInt(e.target.value, 10) || 0;
                dispatch(changeItemAmount(item, amount));
            }
        },
        onClickResource: (item, resource) => () => {
            const resourceItem = itemTable[resource.itemName];

            if (resourceItem.resources.length > 0) {
                dispatch(lockResource(item, resource));
            }
        },
        onClickClear: () => {
            dispatch(clearSelectedItems());
        }
    };
};

const SelectedItemsListComponent = connect(
    mapStateToProps,
    mapDispatchToProps,
)(SelectedItemsList);

export default SelectedItemsListComponent;
