import React from 'react';
import PropTypes from 'prop-types';
import ItemsColumn from './ItemsColumn';

export default function RemovedItemsSection({ deletedItems, numColumns }) {
    const numRemoved = deletedItems.length;
    const columnItems = [];

    for (let i = 0; i < numColumns; i += 1) {
        const start = (numRemoved * i) / numColumns;
        const end = (numRemoved * (i + 1)) / numColumns;
        const items = deletedItems.slice(
            start,
            end > deletedItems.length ? deletedItems.length : end,
        );

        columnItems.push(items);
    }

    if (numRemoved === 0) {
        return null;
    }

    return (
        <>
            <h4>Removed {numRemoved} items</h4>
            <hr />
            <div style={{ display: 'flex', fontSize: 14 }}>
                {columnItems.map((items, index) => (
                    <ItemsColumn
                        key={`removed-column-${index}`}
                        items={items}
                        padding={
                            index !== 0 && index !== columnItems.length - 1
                        }
                    />
                ))}
            </div>
        </>
    );
}

RemovedItemsSection.propTypes = {
    deletedItems: PropTypes.arrayOf(
        PropTypes.shape({
            itemName: PropTypes.string,
            itemUrl: PropTypes.string,
        }),
    ).isRequired,
    numColumns: PropTypes.number,
};

RemovedItemsSection.defaultProps = {
    numColumns: 3,
};
