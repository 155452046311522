export const SELECT_ITEM = 'SELECT_ITEM';
export const DESELECT_ITEM = 'DESELECT_ITEM';
export const ADD_ITEM = 'ADD_ITEM';
export const REMOVE_ITEM = 'REMOVE_ITEM';
export const CHANGE_ITEM_AMOUNT = 'CHANGE_ITEM_AMOUNT';
export const LOCK_RESOURCE = 'LOCK_RESOURCE';
export const SEARCH_ITEM = 'SEARCH_ITEM';
export const SELECT_FILTER = 'SELECT_FILTER';
export const TOGGLE_GETTING_BASE_RESOURCE = 'TOGGLE_GETTING_BASE_RESOURCE';
export const OPEN_FILTER = 'OPEN_FILTER';
export const CLOSE_FILTER = 'CLOSE_FILTER';
export const TOGGLE_FILTER = 'TOGGLE_FILTER';
export const CLEAR_SELECTED_ITEMS = 'CLEAR_SELECTED_ITEMS';
