import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import styles from './material-style';
import { media } from '../../../utils/media';
import Footer from '../../views/CalculatorFullView/Footer';
import HeaderBar from '../HeaderBar';
import FilterDrawer from '../../views/CalculatorFullView/FilterDrawer';

const LayoutStyle = styled.div`
    /* background-color: #fafafa; */

    .main-content {
        /* background-color: #fafafa; */
        display: flex;
        flex-direction: column;
        margin-top: 64px;
        min-height: calc(100vh - 64px);
        width: 100%;
        display: flex;
        z-index: 1;
        overflow: hidden;
        position: relative;

        &.app-content {
            height: calc(100vh - 64px);
        }

        @media print {
            height: 100vh;
            margin-top: 0;
        }
    }

    &.filter-is-open {
        .main-content,
        footer {
            margin-left: auto;
            width: calc(100% - 80px);
        }
    }

    ${media.phone`
        footer {
            display: none;
        }
    `};

    .MuiToolbar-gutters {
        padding-right: 0 !important;
    }

    .MuiDrawer-root {
        width: 80px;
        height: 100vh;
        position: fixed;
        top: 0;
    }
`;

function DesktopHeaderLayout({
    component: Component,
    route,
    classes,
    isFilterBarOpen,
    isApp,
}) {
    const filterOpenClass = isApp && isFilterBarOpen ? 'filter-is-open' : '';
    const layoutClass = `${classes.root} ${filterOpenClass}`;
    const contentClass = `main-content ${isApp ? 'app-content' : ''}`;

    return (
        <LayoutStyle className={layoutClass}>
            <HeaderBar
                classes={classes}
                isApp={isApp}
                history={route.history}
            />
            {isApp && <FilterDrawer />}
            <div className={contentClass}>
                <Component route={route} classes={classes} />
            </div>
            <Footer />
        </LayoutStyle>
    );
}

DesktopHeaderLayout.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    component: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
        .isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    route: PropTypes.object.isRequired,
    isFilterBarOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ calculator: { isFilterBarOpen } }) => ({
    isFilterBarOpen,
    // open: true,
});

export default withStyles(styles, { withTheme: true })(
    connect(mapStateToProps)(DesktopHeaderLayout),
);
