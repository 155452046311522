import nanomemoize from 'nano-memoize';
import {
    SELECT_ITEM,
    DESELECT_ITEM,
    ADD_ITEM,
    REMOVE_ITEM,
    CHANGE_ITEM_AMOUNT,
    SEARCH_ITEM,
    SELECT_FILTER,
    TOGGLE_GETTING_BASE_RESOURCE,
    TOGGLE_FILTER,
    LOCK_RESOURCE,
    CLEAR_SELECTED_ITEMS,
} from '../types/calculator';
import {
    itemTable,
    items,
    itemByDisplayNameTable,
} from '../../components/views/CalculatorFullView/data';
import { itemSortByName } from '../../utils/sort';

const initState = {
    baseResources: [],
    filterItems: [
        {
            name: 'Armor',
            url: '/assets/images/80px-Flak_Chestpiece.png',
            isSelected: false,
        },
        {
            name: 'Weapon',
            url: '/assets/images/80px-Pike.png',
            isSelected: false,
        },
        {
            name: 'Structure',
            url: '/assets/images/80px-Stone_Foundation.png',
            isSelected: false,
        },
        {
            name: 'Consumable',
            url: '/assets/images/80px-Focal_Chili.png',
            isSelected: false,
        },
        {
            name: 'Saddle',
            url: '/assets/images/80px-Parasaur_Saddle.png',
            isSelected: false,
        },
        {
            name: 'Coloring',
            url: '/assets/images/80px-Red_Coloring.png',
            isSelected: false,
        },
    ],
    isGettingBaseResources: true,
    isFilterBarOpen: false,
    items,
    itemTable,
    resources: [],
    search: '',
    searchedItems: items,
    selectedItems: [],
    totalItemWeight: 0,
    totalResourceWeight: 0,
    weightUnknown: false,
};

const getCombinedResources = (
    lineItem,
    isGettingBaseResources = true,
    resourceTable = {},
) => {
    const { item, amount, lockedResources = {} } = lineItem;
    const { resources } = item;
    const { yields } = item;

    for (let j = 0; j < resources.length; j += 1) {
        const resource = resources[j];
        const resourceItem =
            itemTable[resource.itemName] ||
            itemByDisplayNameTable[itemTable[resource.itemName]];

        // Stop getting the base resource if it's circular
        let gettingBaseResources =
            resourceItem.name === item.circularResourceName
                ? false
                : isGettingBaseResources;

        if (lockedResources[resource.itemName]) {
            gettingBaseResources = false;
        }

        if (!resourceItem) {
            console.error("Couldn't find item", resource.itemName);
        } else if (resourceItem.resources.length > 0 && gettingBaseResources) {
            getCombinedResources(
                {
                    item: resourceItem,
                    amount: (resource.amount * amount) / yields,
                    lockedResources: {},
                },
                gettingBaseResources,
                resourceTable,
            );
        } else if (resourceTable[resourceItem.name]) {
            resourceTable[resourceItem.name].amount +=
                (resource.amount * amount) / yields;
        } else {
            resourceTable[resourceItem.name] = {
                amount: (resource.amount * amount) / yields,
                item,
            };
        }
    }

    return resourceTable;
};

const getTotalResources = (lineItems, isGettingBaseResources = true) => {
    const resourceTable = {};
    let totalItemWeight = 0;

    for (let i = 0; i < lineItems.length; i += 1) {
        totalItemWeight += lineItems[i].item.weight * lineItems[i].amount;
        getCombinedResources(
            lineItems[i],
            isGettingBaseResources,
            resourceTable,
        );
    }

    const resources = Object.keys(resourceTable).map((itemName) => ({
        item: itemTable[itemName],
        amount: resourceTable[itemName].amount,
    }));

    const validResources = [];
    let totalResourceWeight = 0;
    let weightUnknown = false;

    for (let i = 0; i < resources.length; i += 1) {
        if (resources[i].amount > 0) {
            validResources.push(resources[i]);
            if (
                Number.isNaN(resources[i].item.weight) ||
                resources[i].item.weight === 0
            ) {
                weightUnknown = true;
            } else {
                totalResourceWeight +=
                    resources[i].item.weight * resources[i].amount;
            }
        }
    }
    return {
        resources: validResources,
        totalItemWeight,
        totalResourceWeight,
        weightUnknown,
    };
};

function createLineItem({ item, amount = 0, lockedResources = {} } = {}) {
    return { item, amount, lockedResources };
}

// TODO: Could be optimized better
const getSelectedItem = (item, selectedItems) => {
    for (let i = 0; i < selectedItems.length; i += 1) {
        if (selectedItems[i].item.name === item.name) {
            return { selectedItem: selectedItems[i], index: i };
        }
    }
    return createLineItem();
};

const handleSelectItem = (state, item) => {
    const selectedItems = state.selectedItems.slice(0);
    const { selectedItem } = getSelectedItem(item, selectedItems);

    if (selectedItem) {
        selectedItem.amount += item.yields || 1;
    } else {
        selectedItems.push(createLineItem({ item, amount: item.yields || 1 }));
    }
    return selectedItems;
};

const handleDeselectItem = (state, item) => {
    const newSelectedItems = state.selectedItems.filter(
        (lineItems) => lineItems.item.name !== item.name,
    );
    return newSelectedItems;
};

const handleAddItem = (state, item, amount) => {
    const selectedItems = state.selectedItems.slice(0);
    const { selectedItem } = getSelectedItem(item, selectedItems);

    if (selectedItem) {
        selectedItem.amount += amount;
    }
    return selectedItems;
};

const handleRemoveItem = (state, item, amount) => {
    const selectedItems = state.selectedItems.slice(0);
    const { selectedItem } = getSelectedItem(item, selectedItems);

    if (selectedItem) {
        selectedItem.amount -= amount;
        if (selectedItem.amount < 0) {
            selectedItem.amount = 0;
        }
    }
    return selectedItems;
};

const handleChangeItemAmount = (state, item, amount) => {
    const selectedItems = state.selectedItems.slice(0);
    const { selectedItem } = getSelectedItem(item, selectedItems);

    if (selectedItem) {
        selectedItem.amount = amount;
        if (selectedItem.amount < 0) {
            selectedItem.amount = 0;
        }
    }
    return selectedItems;
};

const handleLockResource = (state, item, resource) => {
    const selectedItems = state.selectedItems.slice(0);
    const { selectedItem } = getSelectedItem(item, selectedItems);

    if (selectedItem) {
        selectedItem.lockedResources = {
            ...selectedItem.lockedResources,
            [resource.itemName]:
                !selectedItem.lockedResources[resource.itemName],
        };
    }
    return selectedItems;
};

const handleSelectFilter = (index, filterItems) =>
    filterItems.map((item, i) => ({
        ...item,
        isSelected: i === index ? !item.isSelected : item.isSelected,
    }));

const getSearchedItems = nanomemoize((search, itemsList) => {
    const results = !search
        ? itemsList
        : (itemsList &&
              itemsList.filter(
                  (item) => item.lowercasedName.indexOf(search) >= 0,
              )) ||
          [];

    results.sort(itemSortByName);
    return results;
});

const getFilteredItems = nanomemoize((filterItems, itemList) => {
    const filters = filterItems.filter((f) => f.isSelected);

    if (filters.length === 0) return itemList;

    // const t0 = performance.now();
    const itemFilter = nanomemoize((item) => {
        let found = 0;
        for (let i = 0; i < filters.length; i += 1) {
            if (item.types.indexOf(filters[i].name) >= 0) {
                found += 1;
                break;
            }
        }
        return found > 0;
    });
    const results = itemList.filter(itemFilter);
    // const t1 = performance.now();
    // console.log(`filtered in ${t1 - t0}s`);
    return results;
});

const calculator = (state = initState, action) => {
    let selectedItems;
    let resources;
    let filterItems;
    let filteredItems;
    let totalItemWeight;
    let totalResourceWeight;
    let weightUnknown;

    // console.log(action);
    switch (action.type) {
        case SELECT_ITEM:
            selectedItems = handleSelectItem(state, action.item);
            ({
                resources,
                totalItemWeight,
                totalResourceWeight,
                weightUnknown,
            } = getTotalResources(selectedItems, state.isGettingBaseResources));
            return {
                ...state,
                selectedItems,
                resources,
                totalItemWeight,
                totalResourceWeight,
                weightUnknown,
            };
        case DESELECT_ITEM:
            selectedItems = handleDeselectItem(state, action.item);
            ({
                resources,
                totalItemWeight,
                totalResourceWeight,
                weightUnknown,
            } = getTotalResources(selectedItems, state.isGettingBaseResources));
            return {
                ...state,
                selectedItems,
                resources,
                totalItemWeight,
                totalResourceWeight,
                weightUnknown,
            };
        case ADD_ITEM:
            selectedItems = handleAddItem(state, action.item, action.amount);
            ({
                resources,
                totalItemWeight,
                totalResourceWeight,
                weightUnknown,
            } = getTotalResources(selectedItems, state.isGettingBaseResources));
            return {
                ...state,
                selectedItems,
                resources,
                totalItemWeight,
                totalResourceWeight,
                weightUnknown,
            };
        case REMOVE_ITEM:
            selectedItems = handleRemoveItem(state, action.item, action.amount);
            ({
                resources,
                totalItemWeight,
                totalResourceWeight,
                weightUnknown,
            } = getTotalResources(selectedItems, state.isGettingBaseResources));
            return {
                ...state,
                selectedItems,
                resources,
                totalItemWeight,
                totalResourceWeight,
                weightUnknown,
            };
        case CHANGE_ITEM_AMOUNT:
            selectedItems = handleChangeItemAmount(
                state,
                action.item,
                action.amount,
            );
            ({
                resources,
                totalItemWeight,
                totalResourceWeight,
                weightUnknown,
            } = getTotalResources(selectedItems, state.isGettingBaseResources));
            return {
                ...state,
                selectedItems,
                resources,
                totalItemWeight,
                totalResourceWeight,
                weightUnknown,
            };
        case LOCK_RESOURCE:
            selectedItems = handleLockResource(
                state,
                action.item,
                action.resource,
            );
            ({
                resources,
                totalItemWeight,
                totalResourceWeight,
                weightUnknown,
            } = getTotalResources(selectedItems, state.isGettingBaseResources));
            return {
                ...state,
                selectedItems,
                resources,
                totalItemWeight,
                totalResourceWeight,
                weightUnknown,
            };
        case SEARCH_ITEM:
            filteredItems = getFilteredItems(state.filterItems, state.items);

            return {
                ...state,
                searchedItems: getSearchedItems(action.search, filteredItems),
                search: action.search,
            };
        case SELECT_FILTER:
            filterItems = handleSelectFilter(action.index, state.filterItems);
            filteredItems = getFilteredItems(filterItems, state.items);

            return {
                ...state,
                filterItems,
                searchedItems: getSearchedItems(state.search, filteredItems),
            };
        case TOGGLE_GETTING_BASE_RESOURCE:
            ({
                resources,
                totalItemWeight,
                totalResourceWeight,
                weightUnknown,
            } = getTotalResources(
                state.selectedItems,
                !state.isGettingBaseResources,
            ));
            return {
                ...state,
                resources,
                isGettingBaseResources: !state.isGettingBaseResources,
                totalItemWeight,
                totalResourceWeight,
            };
        case TOGGLE_FILTER:
            return {
                ...state,
                isFilterBarOpen: !state.isFilterBarOpen,
            };
        case CLEAR_SELECTED_ITEMS:
            return {
                ...state,
                selectedItems: [],
                resources: [],
                totalItemWeight: 0.0,
                totalResourceWeight: 0,
                weightUnknown: false,
            };
        default:
            return state;
    }
};

export default calculator;
