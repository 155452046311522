import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import ListItem from './ListItem';

export const drawerWidth = 80;

const styles = () => ({
    drawerRoot: {
        width: drawerWidth,
    },
    drawerPaper: {
        position: 'relative',
        width: drawerWidth,
        backgroundColor: '#55967e',
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
});

const StyledList = styled(List)`
    padding: 0 !important;
`;

const CloseButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 64px;

    button {
        margin: auto;
        svg {
            font-size: 50px !important;
            color: #e4e7ec;
        }
    }
`;

class FilterPersistentDrawer extends React.PureComponent {
    render() {
        const { classes, items, open, onClickClose, onSelectItem } = this.props;

        return (
            <Drawer
                variant="persistent"
                open={open}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <CloseButtonWrapper>
                    <IconButton onClick={onClickClose}>
                        <ChevronLeftIcon />
                    </IconButton>
                </CloseButtonWrapper>
                <StyledList>
                    {items.map((item, i) => (
                        <ListItem
                            key={item.id || item.name}
                            url={item.url}
                            name={item.name}
                            selected={item.isSelected}
                            onClick={onSelectItem(i)}
                        />
                    ))}
                </StyledList>
            </Drawer>
        );
    }
}

FilterPersistentDrawer.propTypes = {
    classes: PropTypes.shape({
        drawerPaper: PropTypes.string,
    }).isRequired,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string.isRequired,
            url: PropTypes.string,
            isSelected: PropTypes.bool,
        }),
    ),
    open: PropTypes.bool,
    onSelectItem: PropTypes.func,
    onClickClose: PropTypes.func,
};

FilterPersistentDrawer.defaultProps = {
    open: true,
    onSelectItem: () => {},
    onClickClose: () => {},
    items: [
        {
            name: 'Armor',
            url: '/assets/images/80px-Flak_Chestpiece.png',
            isSelected: false,
        },
        {
            name: 'Weapon',
            url: '/assets/images/80px-Pike.png',
            isSelected: false,
        },
        {
            name: 'Structure',
            url: '/assets/images/80px-Stone_Foundation.png',
            isSelected: false,
        },
        {
            name: 'Consumable',
            url: '/assets/images/80px-Focal_Chili.png',
            isSelected: false,
        },
        {
            name: 'Saddle',
            url: '/assets/images/80px-Parasaur_Saddle.png',
            isSelected: false,
        },
        {
            name: 'Recipe',
            url: '/assets/images/80px-Rockwell_Recipes-_Battle_Tartare.png',
            isSelected: false,
        },
        {
            name: 'Dye',
            url: '/assets/images/80px-Red_Coloring.png',
            isSelected: false,
        },
        // { name: 'Ammunition', url: '/assets/images/80px-Simple_Shotgun_Ammo.png' },
        // { name: 'Tools', url: '/assets/images/80px-GPS.png' },
        // { name: 'Attachments', url: '/assets/images/80px-Flashlight_Attachment.png' },
    ],
};

export default withStyles(styles, { withTheme: true })(FilterPersistentDrawer);
