import React from 'react';
import { getUpdatedItems } from '../../../../utils/get-updated-items';
import Card from '../../../common/Card';
import AddedItemsSection from '../AddedItemsSection';
import RemovedItemsSection from '../RemovedItemsSection';
import UpdatedItemsSection from '../UpdatedItemsSection';
import InfoSection from './InfoSection';
import updateResults from './latest-item-update-results.json';

const { updates } = updateResults;

const addedItems = updates.filter((el) => el.type === 'added');
const deletedItems = updates.filter((el) => el.type === 'removed');
const updatedItems = getUpdatedItems(
    updates
        .filter((el) => el.type === 'updated')
        .map((update) => {
            let weight;
            let resources;

            if (update.weight) {
                weight = {
                    previous: update.weight.old,
                    current: update.weight.new,
                };
            }

            if (update.recipes) {
                const result = update.recipes.reduce(
                    (acc, { ingredients, type: recipeUpdateType }) => {
                        if (recipeUpdateType === 'updated') {
                            ingredients.sort((a, b) =>
                                a.itemName.localeCompare(b.itemName),
                            );
                            ingredients.forEach(
                                ({ itemName, amount, type }) => {
                                    acc[itemName] = {
                                        type:
                                            type === 'removed'
                                                ? 'deleted'
                                                : type,
                                        current: amount.new,
                                        previous: amount.old,
                                    };
                                },
                            );
                        }
                        return acc;
                    },
                    {},
                );

                if (Object.keys(result).length > 0) {
                    resources = result;
                }
            }

            const result = {
                itemName: update.itemName,
                itemUrl: update.itemUrl,
                weight,
                resources,
            };

            if (!weight) {
                delete result.weight;
            }

            if (!resources) {
                delete result.resources;
            }

            if (!weight && !resources) {
                return null;
            }

            return result;
        })
        .filter((update) => update),
);

export const Update09 = () => {
    const hasItemUpdates =
        addedItems.length > 0 ||
        updatedItems.length > 0 ||
        deletedItems.length > 0;

    return (
        <Card title="Update v2.5.1" subtitle="December 30th, 2021">
            <InfoSection
                numAddedItems={addedItems.length}
                numDeletedItems={deletedItems.length}
                numUpdatedItems={updatedItems.length}
            />

            {hasItemUpdates && (
                <>
                    <h3 style={{ color: 'white', marginTop: 0 }}>
                        Items Update
                    </h3>
                    <UpdatedItemsSection updatedItems={updatedItems} />
                    <RemovedItemsSection
                        deletedItems={deletedItems}
                        numColumns={2}
                    />
                    <AddedItemsSection addedItems={addedItems} numColumns={3} />
                </>
            )}
        </Card>
    );
};
