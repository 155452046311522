import React from 'react';
import styled from 'styled-components';
import { media } from '../../../utils/media';
import Version1Section from './Version1Section';
import Version2Section from './Version2Section';
import CreatorSection from './CreatorSection';
import Card from '../../common/Card';

const AboutStyle = styled.div`
    padding: 12px;
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
    box-sizing: border-box;
    letter-spacing: 1px;
    line-height: 24px;
    color: #e4e7ec;
    padding-top: 24px;

    h3 {
        margin-top: 36px;
        color: #263959;
    }

    img {
        width: 100%;
        object-fit: contain;
    }

    li {
        margin-bottom: 10px;
    }

    p {
        margin: 0 0 36px;
    }

    a {
        color: #55967e;
        font-weight: bold;
        text-decoration: unset;
        transition: 200ms color;

        &:hover {
            color: #e4e7ec;
        }
    }

    .update-request-images,
    .feedback-images {
        display: grid;
        grid-gap: 10px;
        grid-template-rows: auto;
        grid-template-columns: auto;
        margin: 36px 0;

        ${media.aboveTablet`
            grid-template-columns: auto auto;
        `};
    }

    .image-container {
        background-color: white;
    }

    .bad-redirect-image-section {
        margin-bottom: 36px;
        display: flex;
        justify-content: center;

        img {
            max-width: 600px;
        }
    }

    .creator-section {
        display: flex;

        .creator-about-me {
            flex: 1;
            padding-right: 10px;
        }

        .creator-beautiful-photo {
            max-height: 360px;
            width: 200px;

            img {
                height: 100%;
            }
        }

        ${media.phone`
            flex-direction: column;

            .creator-beautiful-photo {
                max-height: unset;
                width: 100%;

                img {
                    height: 100%;
                }
            }
        `};
    }
    .card {
        margin-bottom: 24px;
    }
`;

export default function AboutPage() {
    return (
        <AboutStyle>
            <Card title="About">
                <p>
                    The idea for the ARK Resource Calculator was born in January
                    2016 from my desires to learn web development and figure out
                    quickly what resources I needed to build my fort in the
                    game, ARK: Survival Evolved. I played this game all the time
                    with my friends, and always wanted an app to help us craft.
                    There were already other applications out there, but they
                    were either difficult to work with (i.e. spreadsheets) or
                    out of date. I figured building my own calculator would be a
                    great way to provide a better tool for the ARK community and
                    also learn the skills I need to kickstart my career in web
                    development.
                </p>
            </Card>

            <Version2Section />
            <Version1Section />
            <CreatorSection />
        </AboutStyle>
    );
}
