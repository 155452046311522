import React from 'react';
import Card from '../../common/Card';

export default function Version1Section() {
    const NUM_FEEDBACKS = 10;
    const NUM_UPDATE_REQUESTS = 6;
    const feedbackSrcs = [];
    const updateRequestSrcs = [];

    for (let i = 1; i <= NUM_FEEDBACKS; i += 1) {
        const src = `${i.toString().padStart(2, 0)}-feedback.jpeg`;
        feedbackSrcs.push(`/assets/feedback-quotes/${src}`);
    }

    for (let i = 1; i <= NUM_UPDATE_REQUESTS; i += 1) {
        const src = `${i.toString().padStart(2, 0)}-update-request.jpeg`;
        updateRequestSrcs.push(`/assets/update-request-quotes/${src}`);
    }

    return (
        <Card title="Version I">
            <p>
                I built the first version using the MEAN stack (Mongo, Express,
                Angular, Node). It was a popular stack at the time and I figured
                it would help me in the future with job opportunities, but it
                was hell to work with. I didn’t know what I was doing, and I was
                still in my last year in school, so it took around 3-4 months
                until I launched it in April 2016. Towards the end of the
                development, I spent 9 straight hours manually entering data for
                400-500 items from the{' '}
                <a href="https://ark.gamepedia.com/ARK_Survival_Evolved_Wiki">
                    wiki
                </a>{' '}
                because I was too impatient to write a web scraper and wanted to
                get my app out quick. I was so excited, I shared it on{' '}
                <a href="https://www.reddit.com/r/ARK/comments/4eulr3/uptodate_ark_resource_calculator/">
                    Reddit
                </a>
                ! But that was a terrible move because the app was deployed with
                bugs!
            </p>
            <div className="bad-redirect-image-section">
                <img src="/assets/others/bad-redirect-quote.png" alt="" />
            </div>
            <p>
                The problem was with how I set up the app via Digital Ocean. I
                moved to Heroku as it was easier for me at the time to
                understand deployment. It took about a week to get it set up and
                stabled. My very first web application was out in the public for
                people to use. I was proud of myself. Then I started receiving
                emails…
            </p>
            <div className="update-request-images">
                {updateRequestSrcs.map((src) => (
                    <div className="image-container">
                        <img key={src} src={src} alt="" />
                    </div>
                ))}
            </div>
            <p>(and there’s plenty more where those came from)</p>
            <p>
                That day I skipped building a web scraper came back to haunt me.
                I knew I couldn’t keep adding and updating items forever, so I
                finally started building my very first web scraper, and it took
                a few weeks of fine tuning before I got it right. I thought it
                was going to be straight forward, but there were plenty of
                problems I faced.
            </p>
            <p>
                I needed a list of all the items to scrape, so I used the wiki’s
                categories page to scrape all the item links, but later found
                out that some of the items had references to resources that I
                never scraped. In other words, I didn’t scrape all the items I
                needed so that the items can reference their resources without
                error. The category page wasn’t enough. So I asked myself, how
                can I get all the item references that I need? I figured I can
                just add the resource links to my list of items for each item
                I’m iterating through if I didn't have them in my list already.
                And it worked!
            </p>
            <p>
                Not every item page had the same layout. Some redirected to
                another page (i.e.{' '}
                <a href="https://ark.gamepedia.com/Iron_Ingot">Iron Ingot</a>
                ). Some were ambiguous (i.e.{' '}
                <a href="https://ark.gamepedia.com/Metal_Ingot_or_Scrap_Metal_Ingot">
                    Metal Ingot or Scrap Metal Ingot
                </a>
                ). Some were grouped (i.e.{' '}
                <a href="https://ark.gamepedia.com/Dye">Dye</a>). I figured the
                redirected links are items with an alternative name. Ambiguous
                items can be their own items. And I had to scrape all the dyes
                differently than a normal item page.
            </p>
            <p>
                After a while, my IP address was blocked from their site because
                I did a lot of burst scraping, meaning I tried to scrape all the
                links at once. I emailed their support team and we talked it out
                and I had to change the way I was scraping. Working with
                asynchronous Javascript was a pain. This is when I learned about
                callbacks, and I used that pattern to scrape an item one at a
                time.
            </p>
            <p>
                The final process for my web scraper ended up like this: scrape
                for item links -&gt; iterate through item links and scrape, if
                there’s a resource not in the list, add it -&gt; check if all
                items have valid resource references -&gt; update database
            </p>
            <p>
                I was happy about the results. I finally didn’t have to update
                every little item, and less users are disappointed with their
                interaction with my app.
            </p>
            <p>
                Now (7/28/2019), there are over 3000 unique users visiting this
                app every month, and it fills me with joy to know there are so
                many people from all over the world using my app.
            </p>
            <p>
                And what really motivated me was that people were spending their
                time to write me feedbacks to improve the application and send
                me kind words. I don’t want to let them down and I am grateful
                to receive such responses. This experience is why I love
                building web applications.
            </p>
            <p>Here are some of the feedbacks I’ve received.</p>
            <div className="feedback-images">
                {feedbackSrcs.map((src) => (
                    <div className="image-container">
                        <img key={src} src={src} alt="" />
                    </div>
                ))}
            </div>
            <p>
                Thank you everybody for your feedback and suggestions. You’ve
                motivated me to work hard on the next version of the app, and I
                assure you it’s much better.
            </p>
        </Card>
    );
}
