import { connect } from 'react-redux';
import SearchList from './SearchListComponent';
import { selectItem, searchItem } from '../../../../store/actions/calculator';

const mapStateToProps = ({ calculator: { searchedItems, itemTable } }) => ({
    itemTable,
    items: searchedItems,
});

const mapDispatchToProps = (dispatch) => {
    return {
        onClickItem: (item) => () => {
            dispatch(selectItem(item));
        },
        onSearch: (e) => {
            dispatch(searchItem(e.target.value.toLowerCase()));
        },
    };
};

const SearchListComponent = connect(
    mapStateToProps,
    mapDispatchToProps,
)(SearchList);

export default SearchListComponent;
