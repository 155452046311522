import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import GetAppIcon from '@material-ui/icons/GetApp';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import { decimalIfIrrational } from '../../../../utils/number-manipulation';
import BoxHeader from '../../../common/BoxHeader';
import LineItem from './LineItem';
import { exportToCsv } from '../../../../utils/export-to-csv';

const UL = styled.ul`
    margin: 0;
    padding: 8px 0;
    width: 100%;
    overflow: auto;
    height: calc(100% - 14px);

    .default-text {
        flex: 1;
        height: unset;
        padding-top: 24px;
    }
`;

const SelectedItemHeaderLayout = styled.div`
    display: flex;

    .header-section {
        flex: 1;
    }

    .button-section {
        @media print {
            display: none;
        }
    }
`;

const SelectedItemListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow: auto;

    .item-with-amount {
        margin-right: 8px;
    }

    .item-with-amount:last-child() {
        margin-right: 0;
    }
`;

const SelectedItemList = (props) => {
    const {
        selectedItems,
        itemTable,
        isGettingBaseResources,
        onClickRemove,
        onAddAmount,
        onRemoveAmount,
        onChangeAmount,
        onClickResource,
        onClickClear,
        resources,
        totalItemWeight = 0,
        weightUnknown,
    } = props;
    let itemsWeight = decimalIfIrrational(totalItemWeight, 2).toLocaleString(
        undefined,
        {
            minimumFractionDigits: 2,
        },
    );
    const exportItems = () => {
        const rows = [];

        rows.push(["Selected items", ""]);

        selectedItems.forEach(({item, amount}) => {
            rows.push([item.name, amount])
        });

        rows.push(["", ""]);
        rows.push([`Total ${isGettingBaseResources ? "base " : ""}resources`, ""]);

        resources.forEach(({item, amount}) => {
            rows.push([item.name, amount])
        });

        exportToCsv('items.csv', rows)
    }

    if (weightUnknown) {
        itemsWeight = `~ ${itemsWeight}`;
    }

    return (
        <SelectedItemListWrapper className="selected-item-list">
            <BoxHeader>
                <SelectedItemHeaderLayout>
                    <div className="header-section">
                        <div className="header-title">Selected Items</div>
                        <div className="weight-section">
                            Weight: <span className="weight">{itemsWeight}</span>
                        </div>
                    </div>
                    <div className="button-section">
                        <Tooltip title="Clear">
                            <IconButton
                                aria-label="clear"
                                onClick={onClickClear}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Export">
                            <IconButton
                                aria-label="export"
                                onClick={exportItems}
                            >
                                <GetAppIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                </SelectedItemHeaderLayout>
            </BoxHeader>
            <UL>
                {selectedItems.map(({ item, amount, lockedResources }) => (
                    <LineItem
                        key={item.name}
                        item={item}
                        amount={amount}
                        lockedResources={lockedResources}
                        itemTable={itemTable}
                        onClickRemove={onClickRemove}
                        onAddAmount={onAddAmount}
                        onRemoveAmount={onRemoveAmount}
                        onChangeAmount={onChangeAmount}
                        onClickResource={onClickResource}
                    />
                ))}
                {selectedItems.length === 0 && (
                    <li className="default-text">Empty</li>
                )}
            </UL>
        </SelectedItemListWrapper>
    );
};

SelectedItemList.propTypes = {
    selectedItems: PropTypes.arrayOf(
        PropTypes.shape({
            item: PropTypes.shape({
                name: PropTypes.string.isRequired,
            }).isRequired,
            amount: PropTypes.number.isRequired,
        }),
    ),
    isGettingBaseResources: PropTypes.bool,
    resources: PropTypes.arrayOf(
        PropTypes.shape({
            item: PropTypes.shape({
                name: PropTypes.string.isRequired,
            }).isRequired,
            amount: PropTypes.number.isRequired,
        }),
    ),
    onClickRemove: PropTypes.func,
    itemTable: PropTypes.object.isRequired,
    onAddAmount: PropTypes.func,
    onRemoveAmount: PropTypes.func,
    onChangeAmount: PropTypes.func,
    onClickClear: PropTypes.func,
    onClickResource: PropTypes.func,
    totalItemWeight: PropTypes.number,
    weightUnknown: PropTypes.bool,
};

SelectedItemList.defaultProps = {
    selectedItems: [],
    resources: [],
    isGettingBaseResources: false,
    onClickRemove: undefined,
    onAddAmount: undefined,
    onRemoveAmount: undefined,
    onChangeAmount: undefined,
    onClickClear: undefined,
    onClickResource: undefined,
    totalItemWeight: undefined,
    weightUnknown: undefined,
};

export default SelectedItemList;
