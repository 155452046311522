const nanomomoize = require('nano-memoize');
const { DLCImageURLs } = require('../constants/dlc-image-urls');
const getImageFilename = require('../api/ARKScraper/get-image-filename')
    .default;

module.exports.getDLC = (name) => {
    if (name.indexOf('(') >= 0) {
        const regExp = /\(([^)]+)\)/g;
        const matches = name.match(regExp);

        if (!matches) {
            return '';
        }

        for (let i = 0; i < matches.length; i += 1) {
            const dlc = matches[i].substring(1, matches[i].length - 1);

            if (DLCImageURLs[dlc]) {
                if (dlc === 'P+') {
                    return 'Primitive Plus';
                }

                return dlc;
            }
        }
    }
    return '';
};

module.exports.getDisplayName = (name) => {
    const dlc = module.exports.getDLC(name);
    if (dlc) {
        if (name.includes('P+')) {
            return decodeURI(name.replace(` (P+)`, '').trim());
        }
        return decodeURI(name.replace(` (${dlc})`, '').trim());
    }
    // TODO: Edge cases like berry => berries, or coloring => dye
    return decodeURI(name);
};

module.exports.IMG_BASE = '/assets/images/80px-';
// const IMG_BASE = 'http://localhost:3000/images/items-small/80px-';
module.exports.getItemUrl = nanomomoize((itemUrl) => {
    const name = decodeURI(itemUrl.substring(1)).replace("'", '');
    return `${module.exports.IMG_BASE}${name}.png`;
});

module.exports.getItemImageUrl = nanomomoize((itemName) => {
    const name = getImageFilename(itemName);

    return `${module.exports.IMG_BASE}${name}`;
});
