import React from 'react';
import { Link } from 'react-router-dom';
import Card from '../../common/Card';

export default function CreatorSection() {
    return (
        <Card title="The Creator">
            <div className="creator-section">
                <div className="creator-about-me">
                    <p>
                        My name is Akia Vongdara. I’m a software engineer living
                        in the Bay Area. I’ve always been into solving problems
                        and programming, and I’m currently learning the
                        technical and soft skills to help me take on bigger
                        problems in the future. It’s been a long journey, and
                        there’s still plenty more to learn, but I’m having fun
                        doing so.
                    </p>

                    <p>
                        Aside from programming, I practice kendo, rock climb a
                        bit, and work on projects like these on my free time.
                    </p>

                    <p>
                        If you'd like to give me any feedback or suggestions,
                        please{' '}
                        <a
                            href="mailto:vongdarakia@gmail.com?subject=ARK Resource Calculator [Feedback]"
                            target="_top"
                        >
                            email me
                        </a>{' '}
                        about it. Also, if you like my app and would like to
                        support my work, you can do that{' '}
                        <Link to="donate">here</Link> :)
                    </p>

                    <p>
                        Feel free to reach out to me on{' '}
                        <a href="https://www.linkedin.com/in/vongdarakia/">
                            LinkedIn
                        </a>
                    </p>
                </div>
                <div className="creator-beautiful-photo">
                    <img
                        src="/assets/others/about-me.jpeg"
                        alt="Beautiful creator"
                    />
                </div>
            </div>
        </Card>
    );
}
