import PropTypes from 'prop-types';
import React from 'react';

export default function InfoSection({
    numDeletedItems,
    numAddedItems,
    numUpdatedItems,
}) {
    return (
        <div style={{ marginBottom: 32 }}>
            <h3 style={{ color: 'white', marginTop: 0 }}>Summary</h3>

            <article>
                <p>Happy New Year everybody!</p>
            </article>

            <article>
                <ul>
                    {numAddedItems > 0 && <li>Added {numAddedItems} items</li>}
                    {numUpdatedItems > 0 && (
                        <li>Updated {numUpdatedItems} items</li>
                    )}
                    {numDeletedItems > 0 && (
                        <li>
                            Removed {numDeletedItems} unused items from database
                            (mostly uncraftables, unused resources, or the item
                            may have been renamed)
                        </li>
                    )}
                </ul>
            </article>
        </div>
    );
}

InfoSection.propTypes = {
    numDeletedItems: PropTypes.number,
    numAddedItems: PropTypes.number,
    numUpdatedItems: PropTypes.number,
};

InfoSection.defaultProps = {
    numDeletedItems: 0,
    numAddedItems: 0,
    numUpdatedItems: 0,
};
