import React from 'react';
import PropTypes from 'prop-types';

export default function InfoSection({
    numDeletedItems,
    numAddedItems,
    numUpdatedItems,
}) {
    return (
        <div style={{ marginBottom: 32 }}>
            <h3 style={{ color: 'white', marginTop: 0 }}>
                It&apos;s been too long!
            </h3>

            <article>
                <p>
                    It&apos;s been a while, and I&apos;m sorry for the late
                    update guys! Hope everybody is doing well during this time
                    in quarantine.
                </p>
                <p>
                    In this new update, I&apos;ve refactored my web scraper to
                    handle Chemistry Bench ingredients correctly. Before, items
                    like Gun Powder (which uses Sparkpowder), wouldn&apos;t
                    account for their Chemistry Bench ingredients. So the result
                    for Gun Powder (Chemistry Bench) would be its Chemistry
                    bench recipe using normal Sparkpowder recipe.
                </p>
                <p>
                    The scraper now also handles disambiguous items like{' '}
                    <a href="https://ark.gamepedia.com/Cooked_Prime_Meat,_Cooked_Prime_Fish_Meat,_Cooked_Lamb_Chop,_or_Prime_Meat_Jerky">
                        Cooked Prime Meat, Cooked Prime Fish Meat, Cooked Lamb
                        Chop, or Prime Meat Jerky
                    </a>
                    . Before, I had to manually add these guys. Now when it
                    scrapes it, the first item it sees will be used to define
                    this disambiguous item&apos;s stats (i.e. weight,
                    ingredients). This is a good default value for now.
                </p>
                <p>
                    Besides that, I&apos;ve made a huge refactor to my web
                    scraper to have testing for all my important functions and
                    have more functions more bite sized and easily digestible.
                    It&apos;s like I&apos;ve leveled up as a developer. I&apos;m
                    quite happy about how much more maintainable it is now. Each
                    time that I will add a patch, I&apos;ll less likely to have
                    bugs, haha!
                </p>
                <p>
                    And now because of how my code is more modular, I was able
                    to string together all the processes to scrape items, their
                    images, and do a health check for the items, which checks if
                    there&apos;s an image downloaded for the item, and see if
                    all ingredients of that item exists.
                </p>
                <p>
                    Thank you to the people who&apos;ve emailed me feedback
                    about some issues with the calculator. I know I may not have
                    released this update in time for your needs, but your emails
                    really helped motivate me to get this all together. Now
                    updating will be much easier since lots of the manual work
                    have been automated.
                </p>
            </article>

            <h3 style={{ color: 'white', marginTop: 0 }}>Summary</h3>

            <article>
                <ul>
                    <li>Fixed Chemistry Bench items scraping</li>
                    <li>Refactored web scraper to be more maintainable</li>
                    <li>Handle scraping disambiguous items</li>
                    <li>
                        Created a script to scrape items, download images, and
                        checks if all items are good
                    </li>
                    <li>Added {numAddedItems} items</li>
                    <li>Updated {numUpdatedItems} items</li>
                    <li>
                        Removed {numDeletedItems} unused items from database
                        (mostly uncraftables, unused resources, or the item may
                        have been renamed)
                    </li>
                </ul>
            </article>
        </div>
    );
}

InfoSection.propTypes = {
    numDeletedItems: PropTypes.number,
    numAddedItems: PropTypes.number,
    numUpdatedItems: PropTypes.number,
};

InfoSection.defaultProps = {
    numDeletedItems: 0,
    numAddedItems: 0,
    numUpdatedItems: 0,
};
