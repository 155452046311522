import React from 'react';
import PropTypes from 'prop-types';

export default function InfoSection({
    numDeletedItems,
    numAddedItems,
    numUpdatedItems,
}) {
    return (
        <div style={{ marginBottom: 32 }}>
            <h3 style={{ color: 'white', marginTop: 0 }}>Summary</h3>

            <article>
                <p>
                    Hello! It&apos;s been a while, and some of you have requested features to export the items to a spreadsheet and have a printer friendly layout. I&apos;ve been busy, but now have had much more time to work on your requests! I hope you guys enjoy it! Thank you again for those reaching out to request improvements on the app!
                </p>
            </article>

            <article>
                <ul>
                    <li>Add export to CSV (spreadsheet) button</li>
                    <li>Add printer friendly styling</li>
                    {numAddedItems > 0 && <li>Added {numAddedItems} items</li>}
                    {numUpdatedItems > 0 && <li>Updated {numUpdatedItems} items</li>}
                    {numDeletedItems > 0 && (
                        <li>
                            Removed {numDeletedItems} unused items from database
                            (mostly uncraftables, unused resources, or the item may
                            have been renamed)
                        </li>
                    )}
                </ul>
            </article>
        </div>
    );
}

InfoSection.propTypes = {
    numDeletedItems: PropTypes.number,
    numAddedItems: PropTypes.number,
    numUpdatedItems: PropTypes.number,
};

InfoSection.defaultProps = {
    numDeletedItems: 0,
    numAddedItems: 0,
    numUpdatedItems: 0,
};
