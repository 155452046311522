/* eslint-disable dot-notation */
import lzwcompress from 'lzwcompress';
import compressedItems from '../../../data/items.lzw.json';

// const t0 = performance.now();

export const itemTable = lzwcompress.unpack(compressedItems);
export const itemByDisplayNameTable = {};
export const itemsByType = {};
export const items = [];

Object.keys(itemTable).forEach((itemName) => {
    const item = itemTable[itemName];

    itemByDisplayNameTable[item.displayName] = item;

    item.types.forEach((type) => {
        if (itemsByType[type]) {
            itemsByType[type].push(item);
        } else {
            itemsByType[type] = [item];
        }
    });

    if (item.resources.length > 0) {
        items.push(item);
    }
});

items.sort((a, b) => a.displayName.localeCompare(b.displayName));

// console.log('Total items', items);

// const t1 = performance.now();
// console.log(`Call to build itemTable took ${t1 - t0} milliseconds.`);
