import React from 'react';
import Card from '../../common/Card';

export default function Version2Section() {
    const NUM_FEEDBACKS = 10;
    const NUM_UPDATE_REQUESTS = 6;
    const feedbackSrcs = [];
    const updateRequestSrcs = [];

    for (let i = 1; i <= NUM_FEEDBACKS; i += 1) {
        const src = `${i.toString().padStart(2, 0)}-feedback.jpeg`;
        feedbackSrcs.push(`/assets/feedback-quotes/${src}`);
    }

    for (let i = 1; i <= NUM_UPDATE_REQUESTS; i += 1) {
        const src = `${i.toString().padStart(2, 0)}-update-request.jpeg`;
        updateRequestSrcs.push(`/assets/update-request-quotes/${src}`);
    }

    return (
        <Card title="Version II">
            <p>
                With everybody’s feedback, I was inspired to work on a new
                version of the app: faster and with more features. It was also a
                great way to apply what I’ve learned in the recent years and
                solidify my understanding of them. I've been working on this on
                and off since the end of May 2018. This time, I'm going the
                patient route.
            </p>
            <p>
                The new version of the app is made in React. And instead of
                hosting on Heroku, it’s hosted on Netlify, a wonderful
                inexpensive all-in-one platform for web projects. It’s easy to
                set up and manage. I love it, thanks Netlify! (Not sponsored)
            </p>
            <h4>Notable Changes</h4>
            <ul>
                <li>
                    Items aren’t retrieved from a Mongo database anymore,
                    instead it’s from a JSON file that I cleaned up and
                    compressed to a file about the size of 250kb. A database
                    just seems overkill for the amount of items ARK has and what
                    I think they will be having. Having a small file to download
                    all the items is much faster than a database request(s) for
                    all items.
                </li>
                <li>
                    Search is much faster now that on load, it calculate and
                    cache the search results on load for up to 2 characters,
                    then caching any new results after that.
                </li>
                <li>
                    When selecting an item, it’ll add the items by the number of
                    yields. This is more realistic than just 1 because players
                    craft by yields at a time.
                </li>
                <li>Weight is shown for both items and resources.</li>
                <li>
                    Users can toggle between displaying regular resources and
                    base resources.
                </li>
                <li>
                    And the most anticipated feature: users can lock resources
                    to prevent calculating their base resources.
                </li>
            </ul>

            <h4>Scraping Upgrade</h4>
            <p>
                Items weren’t perfect, and there were some cases I didn’t handle
                such as circular resources. For items that had multiple recipes,
                it would combine those ingredients giving an inaccurate result.
                These have now been handled, and by doing so, recipes for items
                made in the Chemistry Bench are captured.
            </p>
            <p>
                Aside from that, I’m working with JavaScript promises, which
                alleviates most of the pain I had from the callback hell. I also
                got most features for the scraper done within a day. A huge
                difference from the weeks it took me previously to finish the
                web scraper.
            </p>
            <p>
                There are plenty more I’d like to do for the app in terms of
                optimization, code quality, maintainability, and a progressive
                web app implementation. Let me know if you have any suggestions.
            </p>
        </Card>
    );
}
