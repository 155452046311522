import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { media, sizes } from '../../../utils/media';
import { PHONE, DESKTOP } from '../../../constants/media-types';

import SearchList from './SearchList';
import SelectedItemsList from './SelectedItemsList';
import ResourceList from './ResourceList';

const AppContainer = styled.div`
    display: grid;
    flex: 1 1 auto;
    height: calc(100% - 64px - 72px);
    grid-gap: 8px;
    padding: 8px;
    box-sizing: border-box;
    max-width: 1400px;
    margin: 0 auto;
    width: 100%;
    position: relative;
    color: #e4e7ec;

    ${media.aboveMobile`
        grid-template-columns: 250px auto;
        grid-template-rows: min-content auto;
        grid-template-areas:
            "resources resources"
            "search-list selected-items";
    `};

    ${media.aboveTablet`
        grid-template-columns: 250px auto;
        grid-template-rows: min-content auto;
        grid-template-areas:
            "search-list resources"
            "search-list selected-items";
    `};

    ${media.phone`
        grid-template-columns: auto;
        grid-template-rows: min-content auto 160px;
        grid-template-areas:
            "resources"
            "selected-items"
            "search-list";
        height: calc(100% - 56px);
        grid-gap: unset;
        padding: unset;
    `};

    .resource-container,
    .selected-items-container,
    .search-list-container {
        position: relative;
        z-index: 2;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19),
            0 6px 6px rgba(0, 0, 0, 0.23);
        background-color: #263959;
        @media print {
            box-shadow: none;
            border: 1px solid gray;
        }
    }

    .resource-container {
        display: flex;
        align-items: center;
        overflow-x: auto;
        /* background-color: #e4e7ec; */
        grid-area: resources;
    }

    .selected-items-container {
        overflow: auto;
        /* background-color: #e4e7ec; */
        /* background-color: #48d1cc; */

        /* filter: opacity(0.59) brightness(0.5); */
        grid-area: selected-items;
    }

    .search-list-container {
        /* background-color: #e4e7ec; */
        grid-area: search-list;
    }

    .opacity,
    .selected-item-list {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .opacity {
        /* filter: opacity(0.5) brightness(0.8); */
        /* background-color: #8283a7; */
        /* z-index: 1; */
    }

    .selected-item-list {
        z-index: 2;
    }

    @media print {
        grid-template-columns: auto;
        grid-template-rows: min-content;
        grid-template-areas:
            "resources"
            "selected-items";

        .search-list-container {
            display: none;
        }
    }
`;

class CalculatorPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            screenType: window.innerWidth <= sizes.phone ? PHONE : DESKTOP,
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this.onResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
    }

    onResize = (win) => {
        const { screenType } = this.state;
        if (screenType !== PHONE && win.target.innerWidth <= sizes.phone) {
            this.setState({ screenType: PHONE });
        } else if (
            screenType !== DESKTOP &&
            win.target.innerWidth > sizes.phone
        ) {
            this.setState({ screenType: DESKTOP });
        }
    };

    render() {
        const { screenType } = this.state;

        return (
            <React.Fragment>
                <AppContainer>
                    <div className="opacity" />
                    <div className="resource-container">
                        <ResourceList className="something" />
                    </div>
                    <div className="selected-items-container">
                        <div className="opacity" />
                        <SelectedItemsList />
                    </div>
                    <div className="search-list-container">
                        <SearchList screenType={screenType} />
                    </div>
                </AppContainer>
            </React.Fragment>
        );
    }
}

CalculatorPage.propTypes = {
    isFilterBarOpen: PropTypes.bool,
    // classes: PropTypes.object.isRequired,
    // theme: PropTypes.object.isRequired,
    filterItems: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            url: PropTypes.string,
            isSelected: PropTypes.bool,
        }),
    ),
};

CalculatorPage.defaultProps = {
    isFilterBarOpen: true,
    filterItems: [
        {
            name: 'Armor',
            url: '/assets/images/80px-Flak_Chestpiece.png',
            isSelected: false,
        },
        {
            name: 'Weapon',
            url: '/assets/images/80px-Pike.png',
            isSelected: false,
        },
        {
            name: 'Structure',
            url: '/assets/images/80px-Stone_Foundation.png',
            isSelected: false,
        },
        {
            name: 'Consumable',
            url: '/assets/images/80px-Focal_Chili.png',
            isSelected: false,
        },
        {
            name: 'Saddle',
            url: '/assets/images/80px-Parasaur_Saddle.png',
            isSelected: false,
        },
        {
            name: 'Recipe',
            url: '/assets/images/80px-Rockwell_Recipes-_Battle_Tartare.png',
            isSelected: false,
        },
        {
            name: 'Dye',
            url: '/assets/images/80px-Red_Coloring.png',
            isSelected: false,
        },
        // { name: 'Ammunition', url: '/assets/images/80px-Simple_Shotgun_Ammo.png' },
        // { name: 'Tools', url: '/assets/images/80px-GPS.png' },
        // { name: 'Attachments', url: '/assets/images/80px-Flashlight_Attachment.png' },
    ],
};

const mapStateToProps = (state) => ({
    isFilterBarOpen: state.calculator.isFilterBarOpen,
});

export default connect(mapStateToProps)(CalculatorPage);
