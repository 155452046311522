import React from 'react';
import PropTypes from 'prop-types';

const Items = PropTypes.arrayOf(
    PropTypes.shape({
        itemName: PropTypes.string,
        itemUrl: PropTypes.string,
    }),
);

const Update = PropTypes.shape({
    itemName: PropTypes.string,
    itemUrl: PropTypes.string,
    current: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    previous: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
});

const RenamedResourceItem = ({ resourceUpdate }) => {
    return (
        <li>
            <b className="yellow">Renamed</b>: {resourceUpdate.previous} -&gt;{' '}
            {resourceUpdate.current}
        </li>
    );
};

RenamedResourceItem.propTypes = {
    resourceUpdate: Update.isRequired,
};

const AddedResourceItem = ({ resourceUpdate }) => {
    return (
        <li>
            <b className="green">Added</b> {resourceUpdate.itemName}: +
            {resourceUpdate.current}
        </li>
    );
};

AddedResourceItem.propTypes = {
    resourceUpdate: Update.isRequired,
};

const RemovedResourceItem = ({ resourceUpdate }) => {
    return (
        <li>
            <b className="red">Removed</b> {resourceUpdate.itemName}
        </li>
    );
};

RemovedResourceItem.propTypes = {
    resourceUpdate: Update.isRequired,
};

const UpdatedResourceItem = ({ resourceUpdate }) => {
    return (
        <li>
            <b className="yellow">Changed</b> {resourceUpdate.itemName}:{' '}
            {resourceUpdate.previous} -&gt; {resourceUpdate.current}
        </li>
    );
};

UpdatedResourceItem.propTypes = {
    resourceUpdate: Update.isRequired,
};

const ResourceUpdates = ({ resourceUpdates }) => {
    return (
        <li>
            <b>Updated</b> resources
            <ul style={{ margin: '0', paddingLeft: 16 }}>
                {resourceUpdates.map((resourceUpdate) => {
                    if (resourceUpdate.type === 'rename') {
                        const itemName =
                            resourceUpdate.itemName || resourceUpdate.current;
                        return (
                            <RenamedResourceItem
                                key={`u03-resource-${itemName}`}
                                resourceUpdate={resourceUpdate}
                            />
                        );
                    }

                    if (resourceUpdate.type === 'added') {
                        return (
                            <AddedResourceItem
                                key={`u03-resource-${resourceUpdate.itemName}`}
                                resourceUpdate={resourceUpdate}
                            />
                        );
                    }

                    if (resourceUpdate.type === 'deleted') {
                        return (
                            <RemovedResourceItem
                                key={`u03-resource-${resourceUpdate.itemName}`}
                                resourceUpdate={resourceUpdate}
                            />
                        );
                    }

                    return (
                        <UpdatedResourceItem
                            key={`u03-resource-${resourceUpdate.itemName}`}
                            resourceUpdate={resourceUpdate}
                        />
                    );
                })}
            </ul>
        </li>
    );
};

ResourceUpdates.propTypes = {
    resourceUpdates: PropTypes.arrayOf(Update).isRequired,
};

const FieldUpdate = ({ field, update }) => {
    return (
        <li>
            <b className="yellow">Changed</b> {field}: {update.previous} -&gt;{' '}
            {update.current}
        </li>
    );
};

FieldUpdate.propTypes = {
    field: PropTypes.string.isRequired,
    update: Update.isRequired,
};

const UpdatedItems = ({ updatedItems }) => {
    if (updatedItems.length === 0) {
        return null;
    }

    return (
        <div style={{ flex: 1 }}>
            {updatedItems.map((update) => {
                const fields = Object.keys(update).filter(
                    (field) => field !== 'itemName' && field !== 'itemUrl',
                );

                return (
                    <div
                        key={`u03-${update.itemName}`}
                        style={{ marginBottom: 8 }}
                    >
                        <span>
                            <a style={{ fontSize: 14 }} href={update.itemUrl}>
                                {update.itemName}
                            </a>
                        </span>
                        <ul
                            style={{
                                fontSize: '12px',
                                margin: '0',
                                paddingLeft: 16,
                            }}
                        >
                            {fields.map((field) => {
                                if (field === 'resources') {
                                    return (
                                        <ResourceUpdates
                                            key={`u03-field-${field}-${update.itemName}`}
                                            resourceUpdates={update.resources}
                                        />
                                    );
                                }

                                return (
                                    <FieldUpdate
                                        key={`u03-field-${field}-${update.itemName}`}
                                        field={field}
                                        update={update[field]}
                                    />
                                );
                            })}
                        </ul>
                    </div>
                );
            })}
        </div>
    );
};

UpdatedItems.propTypes = {
    updatedItems: Items.isRequired,
};

export default function UpdatedItemsSection({ updatedItems }) {
    const updatedItemsHalf1 = updatedItems.slice(0, updatedItems.length / 2);
    const updatedItemsHalf2 = updatedItems.slice(updatedItems.length / 2);

    return (
        <>
            <h4>Updated {updatedItems.length} items</h4>
            <hr />
            <div style={{ display: 'flex' }}>
                <UpdatedItems updatedItems={updatedItemsHalf1} />
                <UpdatedItems updatedItems={updatedItemsHalf2} />
            </div>
        </>
    );
}

UpdatedItemsSection.propTypes = {
    updatedItems: Items.isRequired,
};
