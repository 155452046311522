import { dlcs } from './config';
import getItemName from './parsers/get-item-name';

/* eslint-disable no-console */

export function getFieldValueString(field, fieldNames, fieldValues) {
    for (let i = 0; i < fieldNames.length; i += 1) {
        if (fieldNames[i] === field && i < fieldValues.length)
            return fieldValues[i];
    }
    return '';
}

export function handleNumberParsingError(value, defaultVal) {
    if (
        Number.isNaN(value) ||
        value === '' ||
        value === null ||
        value === undefined
    ) {
        return defaultVal;
    }
    return value;
}

export function parseIntField(itemName, fieldName, fieldNames, fieldValues) {
    const valueStr = getFieldValueString(fieldName, fieldNames, fieldValues);
    let value = 0;

    if (fieldName === 'Stack size') {
        if (itemName === 'Berries') {
            value = 100;
        } else {
            value = 1;
        }
    }

    if (fieldName === 'Crafting Yields' && valueStr) {
        return valueStr.split('/').map((str) => parseInt(str, 10));
    }

    if (valueStr) {
        // Find match for any whole number (including with commas)
        // eslint-disable-next-line no-useless-escape
        const matches = valueStr.match(/(^[+-])?(\d*\,)?\d+/g);

        if (matches) {
            // eslint-disable-next-line no-useless-escape
            value = parseInt(matches[0].replace(/\,/g, ''), 10);
        } else {
            console.log(`Error parsing int field ${fieldName} for ${itemName}`);
        }
    }

    return handleNumberParsingError(value, 0);
}

export function parseFloatField(itemName, fieldName, fieldNames, fieldValues) {
    const valueStr = getFieldValueString(fieldName, fieldNames, fieldValues);
    let value = 0;

    if (valueStr) {
        const matches = valueStr.match(/(^[+-])?(\d*\.)?\d+/g);

        if (matches) {
            value = parseFloat(matches[0]);
        } else {
            console.log(
                `Error parsing float field ${fieldName} for ${itemName}`,
            );
        }
    }
    return handleNumberParsingError(value, 0.0);
}

export function createParseIntField(itemName, fieldNames, fieldValues) {
    return (fieldName) =>
        parseIntField(itemName, fieldName, fieldNames, fieldValues);
}

export function createParseFloatField(itemName, fieldNames, fieldValues) {
    return (fieldName) =>
        parseFloatField(itemName, fieldName, fieldNames, fieldValues);
}

export function parseCategory($, itemName) {
    if (itemName.indexOf(' or ') >= 0) return 'Resource';

    const categories = $('.infobox-section');

    if (categories.length === 0) {
        return '';
    }
    return categories.eq(0).text().replace('\n', '').trim();
}

export function parseFieldNames($) {
    return $('.infobox-row-name b')
        .toArray()
        .map((el) => $(el).text().trim());
}

export function parseFieldValues($) {
    return $('.infobox-row-value')
        .toArray()
        .map((el) => $(el).text().trim().replace(/\n/g, ''));
}

export function removeDLCFromName(name) {
    let result = name;

    dlcs.forEach((dlc) => {
        // Needs the extra space so that when removing from an item with 3 pairs
        // of parenthesis, there won't be an extra space.
        // Ex. Some Item (Kibble) (Mobile) (TEK) -> Some Item (Kibble) (TEK)
        result = result.replace(` (${dlc})`, '').trim();
    });

    return result;
}

export function parseDisplayName(itemName) {
    let newName = itemName;

    newName = removeDLCFromName(newName);
    newName = newName.replace('Rockwell Recipes: ', '').trim();

    return newName;
}

export function getItemNameFromUrl(itemUrl) {
    return getItemName(
        itemUrl
            .split('/')
            [itemUrl.split('/').length - 1].replace('/', '')
            .replace(/_/g, ' ')
            .trim(),
    );
}

export function isPurchaseable($) {
    return $('.infobox-table .infobox-section').text().includes('Purchase');
}

export function getCleanAltNames(itemName, altNames = []) {
    const newAltNames = altNames.slice(0);
    for (let i = 0; i < altNames.length; i += 1) {
        const cleanName = decodeURI(altNames[i]);
        if (cleanName !== itemName) newAltNames.push(cleanName);
    }
    return newAltNames;
}

export function getValueBetweenParenthesis(string) {
    const regExp = /\(([^)]+)\)/;
    const matches = regExp.exec(string);
    return matches ? matches[1] : '';
}

export function getRecipesFromPage($) {
    const infoRows = $('.infobox-table td').toArray();
    const recipeIndexes = [];
    const results = [];

    infoRows.forEach((row, index) => {
        const rowText = $(row).text();
        if (
            rowText === 'Ingredients\n' ||
            rowText.match(/(Ingredients \(.+\))/)
        ) {
            recipeIndexes.push(index);
        }
    });

    recipeIndexes.forEach((index) => {
        const header = $(infoRows[index]).text();
        const recipeName = getValueBetweenParenthesis(header);
        const ingredientElements = $(infoRows[index + 1])
            .find('div[style="padding-left:5px"] b')
            .toArray();
        const ingredientTexts = ingredientElements
            .map((element) => $(element).text())
            .filter((data) => data);
        const ingredients = ingredientTexts.map((text) => {
            const [amountStr, name] = text.split('×');

            return {
                item: { name: name.trim() },
                amount: parseInt(amountStr, 10),
            };
        });

        results.push({
            name: recipeName,
            ingredients,
        });
    });

    return results;
}
