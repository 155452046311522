import React from 'react';
import styled from 'styled-components';
import Card from '../../common/Card';

const StyledUpdates = styled.div`
    padding: 12px;
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
    box-sizing: border-box;
    letter-spacing: 1px;
    line-height: 24px;
    color: #e4e7ec;
    padding-top: 24px;

    p {
        margin: 0 0 36px;
    }

    h3 {
        margin-top: 36px;
        color: #263959;
    }

    ul {
        margin-bottom: 36px;
    }

    a {
        color: #55967e;
        font-weight: bold;
        text-decoration: unset;
        transition: 200ms color;

        &:hover {
            color: #e4e7ec;
        }
    }

    .card {
        margin-bottom: 24px;
    }

    form {
        margin: 0 auto;
        text-align: center;
    }
`;

export default function DonatePage() {
    return (
        <StyledUpdates>
            <Card title="Thank you">
                <p>
                    Hi, I want to thank everybody who's given me feedback and
                    kind regards. I've been working on and off on the ARK
                    Resource Calculator since January 2016, and your kind words
                    have motivated me to work hard to update and add new
                    features. I've learned so much working on this project, and
                    each day I'm trying to improve my habits and code quality. I
                    hope you enjoy the new version of the app. If you'd like to
                    support my work, you can donate a cup of coffee here :)
                </p>
                <div>
                    <form
                        action="https://www.paypal.com/cgi-bin/webscr"
                        method="post"
                        target="_top"
                    >
                        <input type="hidden" name="cmd" value="_donations" />
                        <input
                            type="hidden"
                            name="business"
                            value="2C4KG6QEHVM4E"
                        />
                        <input type="hidden" name="currency_code" value="USD" />
                        <input
                            type="image"
                            src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif"
                            border="0"
                            name="submit"
                            title="PayPal - The safer, easier way to pay online!"
                            alt="Donate with PayPal button"
                        />
                        <img
                            alt=""
                            border="0"
                            src="https://www.paypal.com/en_US/i/scr/pixel.gif"
                            width="1"
                            height="1"
                        />
                    </form>
                </div>
            </Card>
        </StyledUpdates>
    );
}
