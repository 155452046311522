export function decimalIfIrrational(num, numDecimalPlaces) {
    const newNum = num.toFixed(numDecimalPlaces);
    const wholeNum = Math.ceil(newNum);

    if (newNum - wholeNum !== 0) {
        return parseFloat(newNum);
    }
    return wholeNum;
}

export default {
    decimalIfIrrational,
};
