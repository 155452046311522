import React from 'react';
import Card from '../../../common/Card';
import updates03 from './Update07-data.json';
import RemovedItemsSection from '../RemovedItemsSection';
import AddedItemsSection from '../AddedItemsSection';
import InfoSection from './InfoSection';
import { getUpdatedItems } from '../../../../utils/get-updated-items';

const { addedItems, deletedItems, updates } = updates03;

const updatedItems = getUpdatedItems(updates);

export const Update07 = () => {
    const hasItemUpdates = addedItems.length > 0 || updatedItems.length > 0 || deletedItems.length > 0;
    return (
        <Card title="Update v2.4.0" subtitle="February 8th, 2021">
            <InfoSection
                numAddedItems={addedItems.length}
                numDeletedItems={deletedItems.length}
                numUpdatedItems={updatedItems.length}
            />

            {hasItemUpdates && (
                <>
                    <h3 style={{ color: 'white', marginTop: 0 }}>Items Update</h3>
                    <RemovedItemsSection deletedItems={deletedItems} numColumns={1} />
                    <AddedItemsSection addedItems={addedItems} numColumns={1} />
                </>
            )}
        </Card>
    );
};
