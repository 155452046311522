import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
// import * as serviceWorker from './service-worker';

import App from './App';

const mount = document.getElementById('ark-app');
ReactDOM.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>,
    mount,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
