import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ItemButton from './ItemButton';
import { removeDLCFromName } from '../../api/ARKScraper/scraper-helper';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 0.7em;

    @media print {
        .item-amount {
            color: black;
            font-size: 14px;
        }

        margin-top: 8px;
    }
`;

const PrintFriendlyItemName = styled.div`
    display: none;

    @media print {
        color: black;
        display: initial;
    }
`;

// const ItemButtonWithAmount = (props) => (
class ItemButtonWithAmount extends React.Component {
    shouldComponentUpdate(nextProps) {
        return (
            nextProps.item.name !== this.props.item.name ||
            nextProps.amount !== this.props.amount ||
            nextProps.isLocked !== this.props.isLocked
        );
    }

    render() {
        const { style, item, amount, onClick, isLocked } = this.props;

        return (
            <Wrapper className="item-with-amount" style={style}>
                <PrintFriendlyItemName>{removeDLCFromName(item.name)}</PrintFriendlyItemName>
                <ItemButton item={item} onClick={onClick} isLocked={isLocked} />
                <span className="item-amount">{amount.toLocaleString()}</span>
            </Wrapper>
        );
    }
}

ItemButtonWithAmount.propTypes = {
    style: PropTypes.object,
    item: PropTypes.shape({
        name: PropTypes.string.isRequired,
    }),
    amount: PropTypes.any.isRequired,
    isLocked: PropTypes.bool,
};

ItemButtonWithAmount.defaultProps = {
    item: { name: '' },
    isLocked: false,
};

export default ItemButtonWithAmount;
