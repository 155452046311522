import React from 'react';
import PropTypes from 'prop-types';

export default function InfoSection({
    numDeletedItems,
    numAddedItems,
    numUpdatedItems,
}) {
    return (
        <div style={{ marginBottom: 32 }}>
            <h3 style={{ color: 'white', marginTop: 0 }}>Summary</h3>

            <article>
                <ul>
                    <li>Added {numAddedItems} items</li>
                    <li>Updated {numUpdatedItems} items</li>
                    <li>
                        Removed {numDeletedItems} unused items from database
                        (mostly uncraftables, unused resources, or the item may
                        have been renamed)
                    </li>
                </ul>
            </article>
        </div>
    );
}

InfoSection.propTypes = {
    numDeletedItems: PropTypes.number,
    numAddedItems: PropTypes.number,
    numUpdatedItems: PropTypes.number,
};

InfoSection.defaultProps = {
    numDeletedItems: 0,
    numAddedItems: 0,
    numUpdatedItems: 0,
};
