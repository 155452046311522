export const getResourceAmount = (updateResource) => {
    if (updateResource.type === 'added') {
        return updateResource.current;
    }

    if (updateResource.type === 'deleted') {
        return updateResource.previous;
    }

    if (updateResource.type === 'updated') {
        return null;
    }

    throw new Error(
        `Uncaught update type for getResourceAmount: ${updateResource.type}`,
    );
};
