import {
    SELECT_ITEM,
    DESELECT_ITEM,
    ADD_ITEM,
    REMOVE_ITEM,
    CHANGE_ITEM_AMOUNT,
    SEARCH_ITEM,
    SELECT_FILTER,
    TOGGLE_GETTING_BASE_RESOURCE,
    TOGGLE_FILTER,
    LOCK_RESOURCE,
    CLEAR_SELECTED_ITEMS,
} from '../types/calculator';

export const selectItem = (item) => ({
    type: SELECT_ITEM,
    item,
});

export const deselectItem = (item) => ({
    type: DESELECT_ITEM,
    item,
});

export const addItem = (item, amount) => ({
    type: ADD_ITEM,
    item,
    amount,
});

export const removeItem = (item, amount) => ({
    type: REMOVE_ITEM,
    item,
    amount,
});

export const changeItemAmount = (item, amount) => ({
    type: CHANGE_ITEM_AMOUNT,
    item,
    amount,
});

export const lockResource = (item, resource) => ({
    type: LOCK_RESOURCE,
    item,
    resource,
});

export const searchItem = (search) => ({
    type: SEARCH_ITEM,
    search,
});

export const selectFilter = (index) => ({
    type: SELECT_FILTER,
    index,
});

export const toggleGettingBaseResource = () => ({
    type: TOGGLE_GETTING_BASE_RESOURCE,
});

export const toggleFilter = () => ({
    type: TOGGLE_FILTER,
});

export const clearSelectedItems = () => ({
    type: CLEAR_SELECTED_ITEMS,
});
