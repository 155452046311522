import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';

const FILTER_IMG_SIZE = 60;

const styles = {
    listItem: { padding: '9px', paddingBottom: '4px' },
    listItemIcon: { margin: 'auto', display: 'flex', justifyContent: 'center' },
    image: { width: `${FILTER_IMG_SIZE}px`, height: `${FILTER_IMG_SIZE}px` },
};

const IMG = styled.img`
    width: ${FILTER_IMG_SIZE}px;
    height: ${FILTER_IMG_SIZE}px;
`;

const StyledListItem = styled(ListItem)`
    padding: 12px !important;
    padding-bottom: 4px !important;
    padding-top: 4px !important;
    display: flex;
    flex-direction: column;
    transition: 200ms background-color !important;

    ${({ selected }) =>
        selected ? 'background-color: #437563 !important; color: #e4e7ec' : ''};

    .filter-item-name {
        color: #e4e7ec;
        ${({ selected }) => (selected ? 'color: #e4e7ec' : '')};
    }

    &:hover {
        background-color: #437563 !important;
    }
`;

const FilterListItem = ({ selected, onClick, url, name }) => (
    <StyledListItem button selected={selected} onClick={onClick}>
        <ListItemIcon style={styles.listItemIcon}>
            <IMG src={url} alt={name} />
        </ListItemIcon>
    </StyledListItem>
);

FilterListItem.propTypes = {
    url: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    selected: PropTypes.bool,
    onClick: PropTypes.func,
};

FilterListItem.defaultProps = {
    selected: false,
    onClick: undefined,
};

export default FilterListItem;
