import path from 'path';

export const baseUrl = 'https://ark.gamepedia.com';

export const dlcs = [
    'Aberration',
    'Crystal Isles',
    'Extinction',
    'Genesis',
    'Genesis: Part 1',
    'Genesis: Part 2',
    'Mobile',
    'Primitive Plus',
    'P+',
    'Ragnarok',
    'Scorched Earth',
    'The Center',
    'Valguero',
    'Structures Plus',
    'Super Structures',
];

export const DLCS = [
    'Aberration',
    'Crystal Isles',
    'Extinction',
    'Genesis',
    'Genesis: Part 1',
    'Genesis: Part 2',
    'Mobile',
    'Primitive Plus',
    'P+',
    'Ragnarok',
    'Scorched Earth',
    'The Center',
    'Valguero',
    'Structures Plus',
    'Super Structures',
];

export const MODS = ['Structures Plus', 'Super Structures'];

export const MODS_SHORT_NAMES = {
    'Structures Plus': 'S+',
    'Super Structures': 'SS',
};
export const SCRAPER_DIR = path.dirname(require.main.filename);
