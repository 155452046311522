import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import nanomemoize from 'nano-memoize';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import FilterListIcon from '@material-ui/icons/FilterList';
import Nav from './Nav';
import version from '../../../version.json';
import getNthStyledDate from '../../../utils/get-nth-styled-date';
import { media } from '../../../utils/media';

const StyledAppBar = styled(AppBar)`
    background-color: #263959 !important;
    color: #e4e7ec !important;
    padding-right: 20px !important;
    position: relative;

    &.non-app {
        padding-left: 20px !important;
    }

    .MuiToolbar-regular {
        min-height: 64px !important;
    }

    .ark-title {
        display: block;
        font-family: 'Elemental End';
        cursor: pointer;
        transition: 200ms color;

        &:hover {
            color: #6d819c;
        }

        ${media.phone`
            font-size: 14px;
        `}
    }

    #version-section {
        display: block;
        font-size: 10px;
        font-family: monospace;

        #app-version {
            margin-right: 8px;
        }

        .details {
            ${media.phone`
                display: none;
            `}
        }
    }

    @media print {
        display: none !important;
    }
`;

const getAppBarClassName = nanomemoize((classes, isFilterBarOpen) =>
    classNames(classes.appBar, {
        [classes.appBarShift]: isFilterBarOpen,
        [classes['appBarShift-left']]: isFilterBarOpen,
    }),
);

const getIconButtonClassName = nanomemoize((classes, isFilterBarOpen) =>
    classNames(classes.menuButton, isFilterBarOpen && classes.hide),
);

const HeaderBarComponent = (props) => {
    const {
        isFilterBarOpen,
        onDrawerOpen,
        classes = {},
        isApp,
        history,
    } = props;
    const showFilterBar = isApp && isFilterBarOpen;
    const appBarClass = `${getAppBarClassName(classes, showFilterBar)} ${
        !isApp ? 'non-app' : ''
    }`;

    return (
        <StyledAppBar className={appBarClass}>
            <Toolbar disableGutters={!showFilterBar}>
                {isApp && (
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={onDrawerOpen}
                        className={getIconButtonClassName(
                            classes,
                            showFilterBar,
                        )}
                    >
                        <FilterListIcon />
                    </IconButton>
                )}
                <Typography
                    color="inherit"
                    noWrap
                    onClick={() => {
                        history.push('/');
                    }}
                >
                    <span className="ark-title">ARK Resource Calculator</span>
                    <span id="version-section">
                        <span id="app-version">{`v${version.version}`}</span>
                        <span className="details">Released </span>
                        <span id="app-release-date">
                            {getNthStyledDate(version.released)}
                        </span>
                    </span>
                </Typography>
                <Nav />
            </Toolbar>
        </StyledAppBar>
    );
};

export default HeaderBarComponent;
