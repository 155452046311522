import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { store } from './store';
import ARKRoutes from './components/routes';
import './App.css';
import getPageTitle from './utils/get-page-title';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#55967e',
            dark: '#55967e',
        },
        secondary: {
            main: '#ff00ff',
            dark: '#ff00ff',
            light: '#ff00ff',
        },
        background: '#ff00ff',
    },
    overrides: {
        MuiFormLabel: {
            root: {
                color: '#6d819c',
            },
        },
        MuiInput: {
            input: {
                color: '#e4e7ec',
            },
            underline: {
                '&&:before': {
                    borderColor: '#6d819c',
                },
                '&&:hover:before': {
                    borderColor: '#e4e7ec',
                },
            },
        },
    },
});

const App = (props) => {
    const { history, location } = props;

    useEffect(() => {
        document.title = getPageTitle(location.pathname);

        const unlisten = history.listen((nextLocation) => {
            const title = getPageTitle(nextLocation.pathname);

            document.title = title;
            window.gtag('config', 'UA-76022540-1', {
                page_title: title,
                page_path: nextLocation.pathname,
            });
        });

        return () => {
            unlisten();
        };
        // eslint-disable-next-line
    }, []);

    return (
        <Provider store={store}>
            <MuiThemeProvider theme={theme}>
                <ARKRoutes />
            </MuiThemeProvider>
        </Provider>
    );
};

export default withRouter(App);
