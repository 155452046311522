import React from 'react';
import Card from '../../common/Card';

const Update02 = () => {
    const resourceListStyle = { marginBottom: 12 };
    const baseUrl = 'https://ark.gamepedia.com';

    const added = [
        { name: 'Belly Rub Emote', url: `${baseUrl}/Belly_Rub_Emote` },
        {
            name: 'Cooked Meat, Cooked Fish Meat, or Cooked Meat Jerky',
            url: `${baseUrl}/Cooked_Meat,_Cooked_Fish_Meat,_or_Cooked_Meat_Jerky`,
        },
        {
            name:
                'Cooked Prime Meat, Cooked Prime Fish Meat, Cooked Lamb Chop, or Prime Meat Jerky',
            url: `${baseUrl}/Cooked_Prime Meat,_Cooked_Prime_Fish_Meat,_Cooked_Lamb_Chop,_or_Prime_Meat_Jerky`,
        },
        {
            name:
                'Cooked Prime Meat, Cooked Prime Fish Meat, or Prime Meat Jerky',
            url: `${baseUrl}/Cooked_Prime_Meat,_Cooked_Prime_Fish_Meat,_or_Prime_Meat_Jerky`,
        },
        { name: 'Dance Emote', url: `${baseUrl}/Dance_Emote` },
        { name: 'Dino Candy Corn', url: `${baseUrl}/Dino_Candy_Corn` },
        {
            name: 'Element or Eery Element',
            url: `${baseUrl}/Element_or_Eery_Element`,
        },
        { name: 'Food Coma Emote', url: `${baseUrl}/Food_Coma_Emote` },
        {
            name: 'Garden Tree (Mobile)',
            url: `${baseUrl}/Garden_Tree_(Mobile)`,
        },
        { name: 'Hungry Emote', url: `${baseUrl}/Hungry_Emote` },
        {
            name: 'Kibble (Allosaurus Egg) (TEK Kibble Processor)',
            url: `${baseUrl}/Kibble_(Allosaurus_Egg)`,
        },
        {
            name: 'Kibble (Ankylo Egg) (TEK Kibble Processor)',
            url: `${baseUrl}/Kibble_(Ankylo_Egg)`,
        },
        {
            name: 'Kibble (Argentavis Egg) (TEK Kibble Processor)',
            url: `${baseUrl}/Kibble_(Argentavis_Egg)`,
        },
        {
            name: 'Kibble (Archaeopteryx Egg) (TEK Kibble Processor)',
            url: `${baseUrl}/Kibble_(Archaeopteryx_Egg)`,
        },
        {
            name: 'Kibble (Baryonyx Egg) (Mobile)',
            url: `${baseUrl}/Kibble_(Baryonyx_Egg)`,
        },
        {
            name: 'Kibble (Baryonyx Egg) (TEK Kibble Processor)',
            url: `${baseUrl}/Kibble_(Baryonyx_Egg)`,
        },
        {
            name: 'Kibble (Bronto Egg) (TEK Kibble Processor)',
            url: `${baseUrl}/Kibble_(Bronto_Egg)`,
        },
        {
            name: 'Kibble (Carbonemys Egg) (TEK Kibble Processor)',
            url: `${baseUrl}/Kibble_(Carbonemys_Egg)`,
        },
        {
            name: 'Kibble (Compy Egg) (TEK Kibble Processor)',
            url: `${baseUrl}/Kibble_(Compy_Egg)`,
        },
        {
            name: 'Kibble (Carno Egg) (TEK Kibble Processor)',
            url: `${baseUrl}/Kibble_(Carno_Egg)`,
        },
        { name: 'Kibble (Dilo Egg)', url: `${baseUrl}/Kibble_(Dilo_Egg)` },
        {
            name: 'Kibble (Dilo Egg) (TEK Kibble Processor)',
            url: `${baseUrl}/Kibble_(Dilo_Egg)`,
        },
        {
            name: 'Kibble (Dimorph Egg) (TEK Kibble Processor)',
            url: `${baseUrl}/Kibble_(Dimorph_Egg)`,
        },
        {
            name: 'Kibble (Dimetrodon Egg) (TEK Kibble Processor)',
            url: `${baseUrl}/Kibble_(Dimetrodon_Egg)`,
        },
        {
            name: 'Kibble (Diplo Egg) (TEK Kibble Processor)',
            url: `${baseUrl}/Kibble_(Diplo_Egg)`,
        },
        { name: 'Kibble (Dodo Egg)', url: `${baseUrl}/Kibble_(Dodo_Egg)` },
        {
            name: 'Kibble (Dodo Egg) (TEK Kibble Processor)',
            url: `${baseUrl}/Kibble_(Dodo_Egg)`,
        },
        {
            name: 'Kibble (Gallimimus Egg) (TEK Kibble Processor)',
            url: `${baseUrl}/Kibble_(Gallimimus_Egg)`,
        },
        {
            name: 'Kibble (Griffin Egg) (Mobile) (TEK Kibble Processor)',
            url: `${baseUrl}/Kibble_(Griffin_Egg)_(Mobile)`,
        },
        {
            name: 'Kibble (Iguanodon Egg) (TEK Kibble Processor)',
            url: `${baseUrl}/Kibble_(Iguanodon_Egg)`,
        },
        {
            name: 'Kibble (Kairuku Egg) (TEK Kibble Processor)',
            url: `${baseUrl}/Kibble_(Kairuku_Egg)`,
        },
        {
            name: 'Kibble (Kaprosuchus Egg) (TEK Kibble Processor)',
            url: `${baseUrl}/Kibble_(Kaprosuchus_Egg)`,
        },
        {
            name: 'Kibble (Lystrosaurus Egg) (TEK Kibble Processor)',
            url: `${baseUrl}/Kibble_(Lystrosaurus_Egg)`,
        },
        {
            name: 'Kibble (Megalosaurus Egg) (TEK Kibble Processor)',
            url: `${baseUrl}/Kibble_(Megalosaurus_Egg)`,
        },
        {
            name: 'Kibble (Oviraptor Egg) (TEK Kibble Processor)',
            url: `${baseUrl}/Kibble_(Oviraptor_Egg)`,
        },
        {
            name: 'Kibble (Moschops Egg) (TEK Kibble Processor)',
            url: `${baseUrl}/Kibble_(Moschops_Egg)`,
        },
        {
            name: 'Kibble (Parasaur Egg) (TEK Kibble Processor)',
            url: `${baseUrl}/Kibble_(Parasaur_Egg)`,
        },
        {
            name: 'Kibble (Pachyrhino Egg) (TEK Kibble Processor)',
            url: `${baseUrl}/Kibble_(Pachyrhino_Egg)`,
        },
        {
            name: 'Kibble (Pachy Egg) (TEK Kibble Processor)',
            url: `${baseUrl}/Kibble_(Pachy_Egg)`,
        },
        {
            name: 'Kibble (Pteranodon Egg) (TEK Kibble Processor)',
            url: `${baseUrl}/Kibble_(Pteranodon_Egg)`,
        },
        {
            name: 'Kibble (Pelagornis Egg) (TEK Kibble Processor)',
            url: `${baseUrl}/Kibble_(Pelagornis_Egg)`,
        },
        {
            name: 'Kibble (Quetzal Egg) (TEK Kibble Processor)',
            url: `${baseUrl}/Kibble_(Quetzal_Egg)`,
        },
        {
            name: 'Kibble (Raptor Egg) (TEK Kibble Processor)',
            url: `${baseUrl}/Kibble_(Raptor_Egg)`,
        },
        {
            name: 'Kibble (Pulmonoscorpius Egg) (TEK Kibble Processor)',
            url: `${baseUrl}/Kibble_(Pulmonoscorpius_Egg)`,
        },
        {
            name: 'Kibble (Sarco Egg) (TEK Kibble Processor)',
            url: `${baseUrl}/Kibble_(Sarco_Egg)`,
        },
        {
            name: 'Kibble (Rex Egg) (TEK Kibble Processor)',
            url: `${baseUrl}/Kibble_(Rex_Egg)`,
        },
        {
            name: 'Kibble (Stego Egg) (TEK Kibble Processor)',
            url: `${baseUrl}/Kibble_(Stego_Egg)`,
        },
        {
            name: 'Kibble (Tapejara Egg) (TEK Kibble Processor)',
            url: `${baseUrl}/Kibble_(Tapejara_Egg)`,
        },
        {
            name: 'Kibble (Spino Egg) (TEK Kibble Processor)',
            url: `${baseUrl}/Kibble_(Spino_Egg)`,
        },
        {
            name: 'Kibble (Terror Bird Egg) (TEK Kibble Processor)',
            url: `${baseUrl}/Kibble_(Terror_Bird_Egg)`,
        },
        {
            name: 'Kibble (Therizinosaurus Egg) (TEK Kibble Processor)',
            url: `${baseUrl}/Kibble_(Therizinosaurus_Egg)`,
        },
        {
            name: 'Kibble (Trike Egg) (TEK Kibble Processor)',
            url: `${baseUrl}/Kibble_(Trike_Egg)`,
        },
        {
            name: 'Kibble (Titanboa Egg) (TEK Kibble Processor)',
            url: `${baseUrl}/Kibble_(Titanboa_Egg)`,
        },
        {
            name: 'Mosasaur Tek Saddle (Mobile)',
            url: `${baseUrl}/Mosasaur_Tek_Saddle`,
        },
        { name: 'Panic Emote', url: `${baseUrl}/Panic_Emote` },
        {
            name: 'Raw Prime Meat, Raw Prime Fish Meat, or Raw Mutton',
            url: `${baseUrl}/Raw_Prime_Meat,_Raw_Prime_Fish_Meat,_or_Raw_Mutton`,
        },
        {
            name: 'Sloped Tek Wall Left (Mobile)',
            url: `${baseUrl}/Sloped_Tek_Wall_Left`,
        },
        {
            name: 'Sloped Tek Roof (Mobile)',
            url: `${baseUrl}/Sloped_Tek_Roof`,
        },
        {
            name: 'Sloped Tek Wall Right (Mobile)',
            url: `${baseUrl}/Sloped_Tek_Wall_Right`,
        },
        {
            name: 'Tek Fence Foundation (Mobile)',
            url: `${baseUrl}/Tek_Fence_Foundation`,
        },
        { name: 'Tek Grenade (Mobile)', url: `${baseUrl}/Tek_Grenade` },
        {
            name: 'Tek Hatchframe (Mobile)',
            url: `${baseUrl}/Tek_Hatchframe`,
        },
        {
            name: 'TEK Kibble Processor (Mobile)',
            url: `${baseUrl}/TEK_Kibble_Processor_(Mobile)`,
        },
        { name: 'Tek Ladder (Mobile)', url: `${baseUrl}/Tek_Ladder` },
        { name: 'Tek Pillar (Mobile)', url: `${baseUrl}/Tek_Pillar` },
        { name: 'Tek Railing (Mobile)', url: `${baseUrl}/Tek_Railing` },
        { name: 'Tek Ramp (Mobile)', url: `${baseUrl}/Tek_Ramp` },
        {
            name: 'Tek Staircase (Mobile)',
            url: `${baseUrl}/Tek_Staircase`,
        },
        {
            name: 'Tek Teleporter (Mobile)',
            url: `${baseUrl}/Tek_Teleporter`,
        },
        { name: 'Tek Trapdoor (Mobile)', url: `${baseUrl}/Tek_Trapdoor` },
        { name: 'Tek Window (Mobile)', url: `${baseUrl}/Tek_Window` },
        {
            name: 'Tek Windowframe (Mobile)',
            url: `${baseUrl}/Tek_Windowframe`,
        },
        { name: 'Thanksgiving Candy', url: `${baseUrl}/Thanksgiving_Candy` },
        { name: 'Zombie Emote', url: `${baseUrl}/Zombie_Emote` },
    ];

    const renamed = [
        { itemOld: 'Element (AB)', itemNew: 'Element (Aberration)' },
        { itemOld: 'Parchment Coloring', itemNew: 'Parchement Coloring' },
    ];

    const removed = ['Kibble (Troodon Egg) (Mobile)'];

    const updatedColors = [
        {
            name: 'Black Coloring',
            resources: {
                Charcoal: {
                    type: 'updated',
                    previous: 1,
                    current: 2,
                    difference: 1,
                },
                Water: {
                    type: 'updated',
                    previous: 2,
                    current: 1,
                    difference: -1,
                },
            },
        },
        {
            name: 'Blue Coloring',
            resources: {
                Charcoal: {
                    type: 'updated',
                    previous: 1,
                    current: 2,
                    difference: 1,
                },
                Water: {
                    type: 'updated',
                    previous: 2,
                    current: 1,
                    difference: -1,
                },
            },
        },
        {
            name: 'Brown Coloring',
            resources: {
                Charcoal: {
                    type: 'updated',
                    previous: 1,
                    current: 2,
                    difference: 1,
                },
                Tintoberry: {
                    type: 'updated',
                    previous: 6,
                    current: 9,
                    difference: 3,
                },
                Amarberry: {
                    type: 'updated',
                    previous: 3,
                    current: 6,
                    difference: 3,
                },
                Azulberry: {
                    type: 'updated',
                    previous: 9,
                    current: 3,
                    difference: -6,
                },
                Water: {
                    type: 'updated',
                    previous: 2,
                    current: 1,
                    difference: -1,
                },
            },
        },
        {
            name: 'Cyan Coloring',
            resources: {
                Azulberry: {
                    type: 'updated',
                    previous: 6,
                    current: 12,
                    difference: 6,
                },
                Amarberry: {
                    type: 'updated',
                    previous: 12,
                    current: 6,
                    difference: -6,
                },
            },
        },
        {
            name: 'Green Coloring',
            resources: {
                Charcoal: {
                    type: 'updated',
                    previous: 1,
                    current: 2,
                    difference: 1,
                },
                Water: {
                    type: 'updated',
                    previous: 2,
                    current: 1,
                    difference: -1,
                },
            },
        },
        {
            name: 'Mud Coloring',
            resources: {
                Tintoberry: {
                    type: 'updated',
                    previous: 4,
                    current: 7,
                    difference: 3,
                },
                Amarberry: {
                    type: 'updated',
                    previous: 1,
                    current: 4,
                    difference: 3,
                },
                Azulberry: {
                    type: 'updated',
                    previous: 7,
                    current: 1,
                    difference: -6,
                },
            },
        },
        {
            name: 'Orange Coloring',
            resources: {
                Charcoal: {
                    type: 'updated',
                    previous: 1,
                    current: 2,
                    difference: 1,
                },
                Water: {
                    type: 'updated',
                    previous: 2,
                    current: 1,
                    difference: -1,
                },
            },
        },
        {
            name: 'Purple Coloring',
            resources: {
                Charcoal: {
                    type: 'updated',
                    previous: 1,
                    current: 2,
                    difference: 1,
                },
                Water: {
                    type: 'updated',
                    previous: 2,
                    current: 1,
                    difference: -1,
                },
            },
        },
        {
            name: 'Red Coloring',
            resources: {
                Charcoal: {
                    type: 'updated',
                    previous: 1,
                    current: 2,
                    difference: 1,
                },
                Water: {
                    type: 'updated',
                    previous: 2,
                    current: 1,
                    difference: -1,
                },
            },
        },
        {
            name: 'Tan Coloring',
            resources: {
                Tintoberry: {
                    type: 'updated',
                    previous: 4,
                    current: 7,
                    difference: 3,
                },
                Amarberry: {
                    type: 'updated',
                    previous: 1,
                    current: 4,
                    difference: 3,
                },
                Azulberry: {
                    type: 'updated',
                    previous: 7,
                    current: 1,
                    difference: -6,
                },
            },
        },
        {
            name: 'White Coloring',
            resources: {
                Charcoal: {
                    type: 'updated',
                    previous: 1,
                    current: 2,
                    difference: 1,
                },
                Water: {
                    type: 'updated',
                    previous: 2,
                    current: 1,
                    difference: -1,
                },
            },
        },
        {
            name: 'Yellow Coloring',
            resources: {
                Charcoal: {
                    type: 'updated',
                    previous: 1,
                    current: 2,
                    difference: 1,
                },
                Water: {
                    type: 'updated',
                    previous: 2,
                    current: 1,
                    difference: -1,
                },
            },
        },
    ];

    const updated = [
        {
            name: 'Bug Repellant',
            url: `${baseUrl}/Bug_Repellant`,
            updated: { yields: { difference: 5, previous: 1, current: 6 } },
        },
        {
            name: 'Bug Repellant (Chemistry Bench)',
            url: `${baseUrl}/Bug_Repellant`,
            updated: { yields: { difference: 5, previous: 1, current: 6 } },
        },
        {
            name: 'Camelsaurus Egg (Scorched Earth)',
            url: `${baseUrl}/Camelsaurus_Egg_(Scorched_Earth)`,
            updated: { weight: { difference: 9, previous: 0, current: 9 } },
        },
        {
            name: 'Cementing Paste',
            url: `${baseUrl}/Cementing_Paste`,
            updated: { yields: { difference: 5, previous: 1, current: 6 } },
        },
        {
            name: 'Cementing Paste (Chemistry Bench)',
            url: `${baseUrl}/Cementing_Paste`,
            updated: { yields: { difference: 5, previous: 1, current: 6 } },
        },
        {
            name: 'Element',
            url: `${baseUrl}/Element`,
            updated: { yields: { difference: 2, previous: 1, current: 3 } },
        },
        {
            name: 'Flamethrower Ammo (Scorched Earth)',
            url: `${baseUrl}/Flamethrower_Ammo_(Scorched_Earth)`,
            updated: { yields: { difference: 5, previous: 1, current: 6 } },
        },
        {
            name: 'Flamethrower Ammo (Scorched Earth) (Chemistry Bench)',
            url: `${baseUrl}/Flamethrower_Ammo_(Scorched_Earth)`,
            updated: { yields: { difference: 5, previous: 1, current: 6 } },
        },
        {
            name: 'Gunpowder',
            url: `${baseUrl}/Gunpowder`,
            updated: { yields: { difference: 5, previous: 1, current: 6 } },
        },
        {
            name: 'Gunpowder (Chemistry Bench)',
            url: `${baseUrl}/Gunpowder`,
            updated: { yields: { difference: 5, previous: 1, current: 6 } },
        },
        {
            name: 'Lesser Antidote',
            url: `${baseUrl}/Lesser_Antidote`,
            updated: { yields: { difference: 5, previous: 1, current: 6 } },
        },
        {
            name: 'Lesser Antidote (Chemistry Bench)',
            url: `${baseUrl}/Lesser_Antidote`,
            updated: { yields: { difference: 5, previous: 1, current: 6 } },
        },
        {
            name: 'Narcotic',
            url: `${baseUrl}/Narcotic`,
            updated: { yields: { difference: 5, previous: 1, current: 6 } },
        },
        {
            name: 'Narcotic (Chemistry Bench)',
            url: `${baseUrl}/Narcotic`,
            updated: { yields: { difference: 5, previous: 1, current: 6 } },
        },
        {
            name: 'Plant Species Z Fruit (Aberration)',
            url: `${baseUrl}/Plant_Species_Z_Fruit_(Aberration)`,
            updated: { weight: { difference: -11, previous: 21, current: 10 } },
        },
        {
            name: 'Propellant',
            url: `${baseUrl}/Propellant`,
            updated: { yields: { difference: 5, previous: 1, current: 6 } },
        },
        {
            name: 'Propellant (Scorched Earth)',
            url: `${baseUrl}/Propellant_(Scorched_Earth)`,
            updated: { yields: { difference: 5, previous: 1, current: 6 } },
        },
        {
            name: 'Propellant (Scorched Earth) (Chemistry Bench)',
            url: `${baseUrl}/Propellant_(Scorched_Earth)`,
            updated: { yields: { difference: 5, previous: 1, current: 6 } },
        },
        {
            name: 'Stimulant',
            url: `${baseUrl}/Stimulant`,
            updated: { yields: { difference: 5, previous: 1, current: 6 } },
        },
        {
            name: 'Stimulant (Chemistry Bench)',
            url: `${baseUrl}/Stimulant`,
            updated: { yields: { difference: 5, previous: 1, current: 6 } },
        },
        {
            name: 'Tek Ceiling (Mobile)',
            url: `${baseUrl}/Tek_Ceiling`,
            updated: {
                resources: {
                    'Element or Eery Element': {
                        type: 'updated',
                        previous: 10,
                        current: 5,
                        difference: -5,
                    },
                },
            },
        },
        {
            name: 'Tek Door (Mobile)',
            url: `${baseUrl}/Tek_Door`,
            updated: {
                resources: {
                    'Element or Eery Element': {
                        type: 'updated',
                        previous: 5,
                        current: 3,
                        difference: -2,
                    },
                },
            },
        },
        {
            name: 'Tek Double Door',
            url: `${baseUrl}/Tek_Double_Door`,
            updated: { yields: { difference: 3, previous: 1, current: 4 } },
        },
        {
            name: 'Tek Doorframe (Mobile)',
            url: `${baseUrl}/Tek_Doorframe`,
            updated: {
                resources: {
                    'Element or Eery Element': {
                        type: 'updated',
                        previous: 10,
                        current: 5,
                        difference: -5,
                    },
                },
            },
        },
        {
            name: 'Tek Foundation (Mobile)',
            url: `${baseUrl}/Tek_Foundation`,
            updated: {
                resources: {
                    'Element or Eery Element': {
                        type: 'updated',
                        previous: 10,
                        current: 5,
                        difference: -5,
                    },
                },
            },
        },
        {
            name: 'Tek Wall (Mobile)',
            url: `${baseUrl}/Tek_Wall`,
            updated: {
                resources: {
                    'Element or Eery Element': {
                        type: 'updated',
                        previous: 10,
                        current: 5,
                        difference: -5,
                    },
                },
            },
        },
    ];

    return (
        <Card title="Update v2.1.0" subtitle="December 8th, 2019">
            <h3 style={{ color: 'white', marginTop: 0 }}>Items Update</h3>
            <div>Renamed ({renamed.length})</div>
            <ul>
                {renamed.map((item) => (
                    <li key={`u02-renamed-${item.itemOld}`}>
                        {item.itemOld} -> {item.itemNew}
                    </li>
                ))}
            </ul>

            <div>Removed ({removed.length})</div>
            <ul>
                {removed.map((itemName) => (
                    <li key={`u02-removed-${itemName}`}>{itemName}</li>
                ))}
            </ul>

            <div>Updated Colorings ({updatedColors.length})</div>
            <ul>
                {updatedColors.map((item) => (
                    <li key={`u02-updated-${item.name}`}>
                        <a href={`${baseUrl}/dye`}>{item.name}</a>
                        <div>Resource</div>
                        <ul style={resourceListStyle}>
                            {Object.keys(item.resources).map((resourceName) => (
                                <li
                                    key={`u02-resource-${item.name}-${resourceName}`}
                                >
                                    {resourceName}{' '}
                                    {item.resources[resourceName].previous} ->{' '}
                                    {item.resources[resourceName].current}
                                </li>
                            ))}
                        </ul>
                    </li>
                ))}
            </ul>

            <div>Updated ({updated.length})</div>
            <ul>
                {updated.map((item) => (
                    <li key={`u02-updated-${item.name}`}>
                        <a href={item.url}>{item.name}</a>
                        <ul style={resourceListStyle}>
                            {Object.keys(item.updated).map((fieldName) => {
                                if (fieldName === 'resources') {
                                    return (
                                        <li key={`${item.name}-${fieldName}`}>
                                            <div
                                                style={{
                                                    textTransform: 'capitalize',
                                                }}
                                            >
                                                {fieldName}
                                            </div>
                                            <ul style={resourceListStyle}>
                                                {Object.keys(
                                                    item.updated.resources,
                                                ).map((resourceName) => (
                                                    <li
                                                        key={`${item.name}-${fieldName}-${resourceName}`}
                                                    >
                                                        {resourceName}{' '}
                                                        {
                                                            item.updated
                                                                .resources[
                                                                resourceName
                                                            ].previous
                                                        }{' '}
                                                        ->{' '}
                                                        {
                                                            item.updated
                                                                .resources[
                                                                resourceName
                                                            ].current
                                                        }
                                                    </li>
                                                ))}
                                            </ul>
                                        </li>
                                    );
                                }
                                return (
                                    <li key={`${item.name}-${fieldName}`}>
                                        <div>
                                            <span
                                                style={{
                                                    textTransform: 'capitalize',
                                                }}
                                            >
                                                {fieldName}
                                            </span>{' '}
                                            {item.updated[fieldName].previous}{' '}
                                            -> {item.updated[fieldName].current}
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                    </li>
                ))}
            </ul>

            <div>Added ({added.length})</div>
            <ul>
                {added.map((item) => (
                    <li key={`u02-added-${item.name}`}>
                        <a href={item.url}>{item.name}</a>
                    </li>
                ))}
            </ul>
        </Card>
    );
};

export default Update02;
