import React from 'react';
import Card from '../../../common/Card';
import updates03 from './Update03-data.json';
import UpdatedItemsSection from '../UpdatedItemsSection';
import RemovedItemsSection from '../RemovedItemsSection';
import AddedItemsSection from '../AddedItemsSection';
import InfoSection from './InfoSection';
import { getUpdatedItems } from '../../../../utils/get-updated-items';

const { addedItems, deletedItems, updates } = updates03;

const updatedItems = getUpdatedItems(updates);

const Update03 = () => {
    return (
        <Card title="Update v2.2.0" subtitle="June 26th, 2020">
            <InfoSection
                numAddedItems={addedItems.length}
                numDeletedItems={deletedItems.length}
                numUpdatedItems={updatedItems.length}
            />

            <h3 style={{ color: 'white', marginTop: 0 }}>Items Update</h3>
            <UpdatedItemsSection updatedItems={updatedItems} />
            <RemovedItemsSection deletedItems={deletedItems} />
            <AddedItemsSection addedItems={addedItems} />
        </Card>
    );
};

export default Update03;
