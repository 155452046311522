import { isResourceUpdateARename } from './is-resource-update-a-rename';

export const getUpdatedItems = (updates) => {
    return updates.map((item) => {
        const resourceNames = Object.keys(item.resources || {});
        const resources = [];
        const result = { ...item };

        // Handle renames
        for (let i = 0; i < resourceNames.length; i += 2) {
            const resource1Name = resourceNames[i];
            const resource2Name = resourceNames[i + 1];
            const resource1 = item.resources[resource1Name];
            const resource2 =
                i + 1 < resourceNames.length
                    ? item.resources[resource2Name]
                    : null;

            if (
                isResourceUpdateARename(
                    resource1,
                    resource2,
                    resource1Name,
                    resource2Name,
                )
            ) {
                const current =
                    resource1.type === 'added' ? resource1Name : resource2Name;
                const previous =
                    resource1.type === 'deleted'
                        ? resource1Name
                        : resource2Name;

                resources.push({ type: 'rename', current, previous });
            } else if (resource1 && resource2) {
                if (resource1.type !== 'updated') {
                    resources.push({ ...resource1, itemName: resource1Name });
                }
                if (resource2.type !== 'updated') {
                    resources.push({ ...resource2, itemName: resource2Name });
                }
            } else if (resource1) {
                if (resource1.type !== 'updated') {
                    resources.push({ ...resource1, itemName: resource1Name });
                }
            }
        }

        // Handle updates
        for (let i = 0; i < resourceNames.length; i += 1) {
            const resource1Name = resourceNames[i];
            const resource1 = item.resources[resource1Name];

            if (resource1.type === 'updated') {
                resources.push({ itemName: resource1Name, ...resource1 });
            }
        }

        if (resources.length > 0) {
            result.resources = resources;
        }

        return result;
    });
};
